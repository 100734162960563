import { showErrorToast, showToast } from '~/utils/toast-notifications';

export default {
  getHeadlines(context, payload) {
    const { noSkeletons } = payload ?? {};

    // NOTE: With this in place, we only show skeletons if we don't explicitly specify that we don't want skeletons,
    // AND the related data hasn't ALREADY been loaded.
    // This is to prevent unnecessary skeleton showing if the data has already been loaded.
    // Instead, we simply replace the existing data and the UI updates accordingly.
    if (!noSkeletons && context.state.headlines === null)
      context.commit('GET_HEADLINES_START');

    this.dispatch('api/getNewsHeadlines')
      .then((res) => {
        context.commit('GET_HEADLINES_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_HEADLINES_ERROR', err);
      });
  },
  getFeed(context, payload) {
    const { noSkeletons } = payload ?? {};

    // NOTE: With this in place, we only show skeletons if we don't explicitly specify that we don't want skeletons,
    // AND the related data hasn't ALREADY been loaded.
    // This is to prevent unnecessary skeleton showing if the data has already been loaded.
    // Instead, we simply replace the existing data and the UI updates accordingly.
    if (!noSkeletons && context.state.feed === null)
      context.commit('GET_FEED_START');

    const apiParams = { ...payload };
    delete apiParams.noSkeletons;

    this.dispatch('api/getNews', apiParams)
      .then((res) => {
        context.commit('GET_FEED_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_FEED_ERROR', err);
      });
  },
  incrementReplyCount(context, payload) {
    context.commit('INCREMENT_REPLYCOUNT', payload.postId);
  },
  incrementRepostCount(context, payload) {
    context.commit('INCREMENT_REPOSTCOUNT', payload.postId);
  },
  likePost(context, payload) {
    this.dispatch('api/likePost', payload)
      .then((res) => {
        context.commit('UPDATE_POST_LIKE', res.data);
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  favoritePost(context, payload) {
    this.dispatch('api/favoritePost', payload)
      .then((res) => {
        context.commit('UPDATE_POST_FAVORITE', res.data);
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  getNextPosts(context, payload) {
    context.commit('SET_NEXT_POSTS_LOADING', true);
    return this.dispatch('api/getNews', payload)
      .then((res) => {
        context.commit('APPEND_POSTS_TO_FEED', res.data);
        return res;
      })
      .catch((err) => {
        context.commit('GET_FEED_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_NEXT_POSTS_LOADING', false);
      });
  },
  postComment(context, payload) {
    return this.dispatch('api/createPost', payload)
      .then((res) => {
        showToast(window.$nuxt.$t('post_created_ok'));
        context.commit('APPEND_NEW_REPLY', res.data);
      })
      .catch((err) => {
        showErrorToast(window.$nuxt.$t('error_occured_message'));
        console.dir(err);
        context.commit('SET_REPLIES_ERROR', {
          err: err,
          postId: payload.target,
        });
      });
  },
  likeReply(context, payload) {
    this.dispatch('api/likePost', payload.replyId)
      .then((res) => {
        context.commit('UPDATE_REPLY', res.data);
      })
      .catch((err) => {
        context.commit('SET_REPLIES_ERROR', {
          err: err,
          postId: payload.replyId,
        });
      });
  },
  favoriteReply(context, payload) {
    this.dispatch('api/favoritePost', payload)
      .then((res) => {
        context.commit('UPDATE_REPLY', res.data);
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  incrementReplyReplyCount(context, payload) {
    if (payload && payload.postId) {
      context.commit('INCREMENT_REPLY_REPLY_COUNT', payload);
    }
  },
  updateReply(context, payload) {
    return this.dispatch('api/updatePost', payload.data)
      .then((res) => {
        showToast(window.$nuxt.$t('post_updated_ok'));
        res.data.updatedDate = payload.data.updatedDate;
        res.data.postId = payload.postId;
        context.commit('UPDATE_REPLY_AFTER_EDIT', {
          postId: payload.postId,
          data: res.data,
        });
      })
      .catch((err) => {
        showErrorToast(window.$nuxt.$t('error_occured_message'));
        context.commit('SET_REPLIES_ERROR', {
          err: err,
          postId: payload.target,
        });
      });
  },
  updateNestedReply(context, payload) {
    return this.dispatch('api/updatePost', payload.data)
      .then((res) => {
        showToast(window.$nuxt.$t('post_updated_ok'));
        res.data.updatedDate = payload.data.updatedDate;
        context.commit('UPDATE_NESTED_REPLY_AFTER_EDIT', {
          postId: payload.postId,
          replyId: payload.replyId,
          nestedReplyId: payload.nestedReplyId,
          data: res.data,
        });
      })
      .catch((err) => {
        showErrorToast(window.$nuxt.$t('error_occured_message'));
        context.commit('SET_REPLIES_ERROR', {
          err: err,
          postId: payload.data.target,
        });
      });
  },
  /// Nested Replies
  loadNestedReplies(context, payload) {
    return this.dispatch('api/getPostReplies', {
      postId: payload.replyId,
      params: payload.params,
    })
      .then((res) => {
        context.commit('SET_NESTED_REPLIES', {
          replies: res.data,
          postId: payload.postId,
          replyId: payload.replyId,
        });
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  loadNextNestedReplies(context, payload) {
    return this.dispatch('api/getPostReplies', {
      postId: payload.replyId,
      params: payload.params,
    })
      .then((res) => {
        context.commit('APPEND_NESTED_REPLY_BOTTOM', {
          replies: res.data,
          postId: payload.postId,
          replyId: payload.replyId,
        });
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  appendNestedReplyTop(context, payload) {
    context.commit('APPEND_NESTED_REPLY_TOP', payload);
  },
  favoriteNestedReply(context, payload) {
    return this.dispatch('api/favoritePost', payload.nestedReplyId)
      .then((res) => {
        context.commit('UPDATE_NESTED_REPLY', {
          data: res.data,
          postId: payload.postId,
        });
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  likeNestedReply(context, payload) {
    return this.dispatch('api/likePost', payload.nestedReplyId)
      .then((res) => {
        context.commit('UPDATE_NESTED_REPLY', {
          data: res.data,
          postId: payload.postId,
        });
      })
      .catch((err) => {
        context.commit('SET_REPLIES_ERROR', {
          err: err,
          postId: payload.replyId,
        });
      });
  },
  incrementNestedReplyReplyCount(context, payload) {
    context.commit('INCREMENT_NESTED_REPLY_REPLY_COUNT', payload);
  },

  // Trending Indices
  getAllTrendingIndices(context, payload) {
    const { noSkeletons } = payload ?? {};

    // NOTE: With this in place, we only show skeletons if we don't explicitly specify that we don't want skeletons,
    // AND the related data hasn't ALREADY been loaded.
    // This is to prevent unnecessary skeleton showing if the data has already been loaded.
    // Instead, we simply replace the existing data and the UI updates accordingly.
    if (!noSkeletons && context.state.allTrendingIndices === null)
      context.commit('SET_ALL_TRENDING_INDICES_LOADING', true);

    return this.dispatch('api/getAllTrendingIndices')
      .then((res) => {
        context.commit('SET_ALL_TRENDING_INDICES', res.data);
      })
      .catch((err) => context.commit('SET_ALL_TRENDING_INDICES_ERROR', err))
      .finally(() => {
        context.commit('SET_ALL_TRENDING_INDICES_LOADING', false);
      });
  },

  deletePostReply(context, payload) {
    return this.dispatch('api/deleteUserPost', {
      postId: payload.replyId,
    })
      .then((res) => {
        context.commit('DELETE_USER_POST_REPLY_SUCCESS', {
          postId: payload.postId,
          replyId: res.data,
        });
        showToast(window.$nuxt.$t('post_deletion_successful'));
      })
      .catch((err) => {
        context.commit('DELETE_USER_POST_REPLY_ERROR', err);
        showErrorToast(window.$nuxt.$t('error_occured_message'));
      });
  },
  deletePostNestedReply(context, payload) {
    return this.dispatch('api/deleteUserPost', {
      postId: payload.nestedReplyId,
    })
      .then((res) => {
        context.commit('DELETE_POST_NESTED_REPLY_SUCCESS', {
          postId: payload.postId,
          replyId: payload.replyId,
          nestedReplyId: res.data,
        });
        showToast(window.$nuxt.$t('post_deletion_successful'));
      })
      .catch((err) => {
        context.commit('DELETE_POST_NESTED_REPLY_ERROR', err);
        showErrorToast(window.$nuxt.$t('error_occured_message'));
      });
  },
};
