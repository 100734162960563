
import Modals from '@/components/shared/modals';
import Banners from '@/components/shared/banners';
import Nav from '@/components/shared/nav';
import Cookies from '@/components/shared/cookies';
import Logo from '@/assets/icons/logo.svg';
import { getWindowInstance } from '~/plugins/windowInstance';
import {
  getAccessToken,
  getUser,
  loginByRedirect,
  loginRedirectCallback,
  logout,
  refreshSession,
  userManager,
} from '@/utils/behavior/authService';
import { appURLs, inAppRedirect } from '~/utils/route-redirects';
import { AnonymousRedirectURL } from '~/utils/env-vars';
import signalR from '@/plugins/signalr/index';
import { unlocalizeRouteName } from '~/utils/routeNames';
import { mapGetters, mapActions } from 'vuex';
import { AppLoadQueryParams } from '~/utils/behavior/localStorage/localStorageKeys';
import { decodeJWT } from '~/utils/behavior/jwt';

const contextualizedLogMessage = (message) => `Shared Layout. ${message}`;

// TODO adjust this
const _loginTimeoutMillis = 7000;

export default {
  name: 'SharedLayout',
  components: { Modals, Banners, Nav, Cookies, Logo },
  props: {
    showNav: {
      type: Boolean,
      required: false,
      default: true,
    },
    showModals: {
      type: Boolean,
      required: false,
      default: true,
    },
    showBanners: {
      type: Boolean,
      required: false,
      default: true,
    },
    showCookies: {
      type: Boolean,
      required: false,
      default: true,
    },
    showWidgets: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      // TODO
      loginTimeout: null,
    };
  },
  computed: {
    ...mapGetters('layouts', ['loggedIn']),
    isMobileResponsive() {
      // @ts-ignore TODO fix typescript not recognizing this
      return getWindowInstance(this).isMobileResponsive();
    },
  },
  async mounted() {
    console.debug(contextualizedLogMessage('Mounted'));

    // TODO remove this line
    window.userManager = userManager;

    this.setLoginTimeout();

    // TODO add special treatment in case the user is a web crawler

    const {
      shouldRefreshAccessToken,
      code,
      state,
      scope,
      session_state,
      axeptio_token: axeptioToken,
      mobileApp,
    } = this.$route.query;

    const redirectToLogin = this.$route.query.redirectToLogin === 'true';

    console.debug(contextualizedLogMessage(`Query param state`), {
      shouldRefreshAccessToken,
      code,
      scope,
      state,
      session_state,
      axeptioToken,
      mobileApp,
    });

    let loginRedirectCallbackSuccess = false;
    let userAvailable = false;
    let user = undefined;
    let newlyRegistered = false;

    // TODO extract into function to simplify the main flow

    // save existing route or query parameters, so that we can redirect the user back to where they were
    this.saveQueryParams({ axeptioToken });

    // only call the login redirect callback if there are expected URL query parameters
    if (code && state && scope) {
      console.debug(
        contextualizedLogMessage('Starting login redirect callback')
      );

      try {
        user = await loginRedirectCallback();

        console.debug(
          contextualizedLogMessage(
            'Login redirect callback finished. Obtained user'
          ),
          user
        );

        userAvailable = true;
        loginRedirectCallbackSuccess = true;
      } catch (ex) {
        console.error(
          contextualizedLogMessage('Failed to execute login redirect callback'),
          ex
        );

        userAvailable = false;
        loginRedirectCallbackSuccess = false;
      }
    } else
      console.debug(
        contextualizedLogMessage(
          'One of `code`, `state` or `scope` is not available. Skipping login redirect callback'
        )
      );

    // TODO extract into function to simplify the main flow

    try {
      console.debug(contextualizedLogMessage('Getting user.'));
      user = await getUser();

      if (!user) {
        console.debug(contextualizedLogMessage('No user found.'));
        userAvailable = false;
      } else {
        console.debug(contextualizedLogMessage('Found user.', user));
        userAvailable = true;
      }
    } catch (ex) {
      console.error(contextualizedLogMessage('Failed to get user'));

      userAvailable = false;
    }

    console.debug(contextualizedLogMessage('Login state checkpoint'), {
      loginRedirectCallbackSuccess,
      userAvailable,
    });

    // TODO extract into function to simplify the main flow

    if (!userAvailable) {
      console.debug(
        contextualizedLogMessage(
          'User is unavailable. Deciding the next redirect'
        )
      );

      // in case url contains the mobile device query parameter, redirect the user to the welcome page
      if (this.$route.query.mobileApp !== undefined) {
        console.debug(
          contextualizedLogMessage(
            'Mobile device query parameter detected. Redirecting to welcome page'
          )
        );
        await this.redirectToWelcome();
        return;
      }

      // in case there is a is a `redirectToLogin` query parameter, and it's true, redirect the user to login
      if (redirectToLogin) {
        console.debug(
          contextualizedLogMessage(
            'Mobile app query parameter detected. Redirecting to login'
          )
        );
        await loginByRedirect();
        return;
      }

      console.debug(
        contextualizedLogMessage(
          `Redirecting to ${AnonymousRedirectURL} by default`
        )
      );

      // handle the case where user is not logged in
      window.location.href = AnonymousRedirectURL;
      return;
    }

    if (user.expired) {
      console.debug(
        contextualizedLogMessage(
          'User session has expired. Attempting token refresh'
        )
      );

      user = await refreshSession();

      console.debug(
        contextualizedLogMessage('Successfully refreshed user token')
      );

      userAvailable = true;
    }

    console.debug(contextualizedLogMessage('Login state checkpoint'), {
      loginRedirectCallbackSuccess,
      userAvailable,
    });

    console.debug(
      contextualizedLogMessage('User is available. Login finished')
    );

    // refresh access token based on query param
    if (shouldRefreshAccessToken === 'true') {
      console.debug(
        contextualizedLogMessage(
          'Refreshing access token due to the query parameter `shouldRefreshAccessToken`'
        )
      );

      await refreshSession();

      console.debug(
        contextualizedLogMessage(
          'Finished refreshing access token due to the query parameter `shouldRefreshAccessToken`'
        )
      );
    }

    await this.updateDecodedToken();

    try {
      newlyRegistered = await this.enrichStoreWithAuth();
    } catch (ex) {
      console.error(
        contextualizedLogMessage(
          'Failed to enrich store with auth. Logging out the user and rethrowing.'
        ),
        ex
      );

      await logout();

      throw ex;
    }

    this.clearLoginTimeout();

    this.updateQueryParams();

    this.registerSignalR();

    if (this.handleOnboardingRedirect(newlyRegistered, axeptioToken)) {
      console.debug(
        contextualizedLogMessage(
          'Since the user should be redirected to onboarding, skip setting the logged in state in this layout instance'
        )
      );

      return;
    }

    console.debug(
      contextualizedLogMessage(
        'User is considered logged in. Allowing user to see the page'
      )
    );

    this.setLoggedIn(true);

    await this.setupVisibilityHandlers();

    await this.setupSWHandlers();

    if (mobileApp) localStorage.setItem('mobileApp', mobileApp);
  },
  beforeDestroy() {
    this.clearLoginTimeout();
  },
  methods: {
    ...mapActions('layouts', ['setLoggedIn']),
    getAppLoadQueryParams() {
      try {
        return JSON.parse(localStorage.getItem(AppLoadQueryParams) ?? '{}');
      } catch (e) {
        console.error(
          contextualizedLogMessage(
            'Failed to parse saved app load query params. Returning empty object'
          )
        );
        return {};
      }
    },
    setAppLoadQueryParams(params) {
      localStorage.setItem(AppLoadQueryParams, JSON.stringify(params));
    },
    clearAppLoadQueryParams() {
      localStorage.setItem(AppLoadQueryParams, JSON.stringify({}));
    },
    saveQueryParams({ axeptioToken }) {
      try {
        // fetch already saved query params
        const alreadySavedParams = this.getAppLoadQueryParams();

        // specify more query parameters that should be saved here
        const paramsToSave = {
          axeptio_token: axeptioToken,
          ...alreadySavedParams,
        };

        console.debug(
          contextualizedLogMessage('Saving query params'),
          paramsToSave
        );

        this.setAppLoadQueryParams(paramsToSave);
      } catch (ex) {
        console.error(
          contextualizedLogMessage(`Failed to save query parameters`),
          ex
        );
      }
    },
    /**
     * Sets the login timeout timer.
     * The timer redirects the user back to login in case login does not succeed within the duration of the timer.
     * This timer should be cleared if login succeeds.
     *
     * @function setLoginTimeout
     * @returns {void}
     */
    setLoginTimeout() {
      console.debug(contextualizedLogMessage('Setting login timeout.'));

      // In case login isnt complete or determined within `_loginTimeoutMillis` milliseconds, redirect to login
      this.loginTimeout = window.setTimeout(async () => {
        console.error(contextualizedLogMessage('Login timeout reached.'));

        const user = await getUser();

        const storeMe = this.$store.getters['authentication/me'];

        // TODO decide on whether to logout regardless or not, as it impacts the overall behavior
        if (!user) {
          console.error(
            contextualizedLogMessage(
              'Login timeout found no user. Redirecting to login'
            )
          );

          await loginByRedirect();
        } // if the user's session has expired despite waiting, logout the user
        else if (user.expired) {
          console.error(
            contextualizedLogMessage('User session expired. Logging out'),
            user
          );

          await logout();
        } // if theres no profile present, logout the user
        else if ((storeMe?.profiles?.length ?? 0) === 0) {
          console.error(
            contextualizedLogMessage('No profile found for user. Logging out')
          );

          await logout();
        } else {
          console.info(
            contextualizedLogMessage(
              'Login timeout found a valid user. Exiting timeout'
            )
          );
        }
      }, _loginTimeoutMillis);
    },
    /**
     * Updates the store information with the decoded access token, if available.
     * Returns otherwise.
     *
     * @function updateDecodedToken
     * @returns {Promise<void>}
     */
    async updateDecodedToken() {
      const user = await userManager.getUser();

      const token = user?.access_token;

      if (!token) {
        console.error(
          contextualizedLogMessage(
            'Cannot update decoded token, as the token is unavailable. This is unexpected behavior'
          )
        );
        return;
      }

      const decodedToken = decodeJWT(token);

      console.debug(contextualizedLogMessage('Setting decoded token'), {
        token,
        decodedToken,
      });

      this.$store.dispatch('authentication/setDecodedToken', decodedToken);
    },
    /**
     * Check if `this.loginTimeout` is set and clear that timeout if so, while setting `this.loginTimeout` to `null`.
     * Immediately returns otherwise.
     *
     * @function clearLoginTimeout
     * @returns {void}
     */
    clearLoginTimeout() {
      if (!this.loginTimeout) {
        console.debug(
          contextualizedLogMessage('No login timeout was set. Returning')
        );
        return;
      }

      console.debug(contextualizedLogMessage('Clearing login timeout'));
      window.clearTimeout(this.loginTimeout);
      this.loginTimeout = null;
    },
    /**
     * Asynchronously checks if the user is registered and updates the store with the user's authentication and profile information.
     * If the user is not registered, it creates a new user and profile in the API.
     *
     * @async
     * @function enrichStoreWithAuth
     * @returns {Promise<boolean>} Returns a promise that resolves to a boolean indicating whether a new user was registered.
     */
    async enrichStoreWithAuth() {
      console.debug(
        contextualizedLogMessage('Checking whether the user is registered')
      );

      let newlyRegistered = false;

      await this.$store.dispatch('authentication/getMe');
      const me = this.$store.getters['authentication/me'];

      // TODO check if any additional conditions are required
      if (!me) {
        // New account, need to create the user and the profile in the API
        console.debug(
          contextualizedLogMessage(
            'User not found. A newly registered user should now be logged in. Creating the user and profile'
          )
        );

        newlyRegistered = true;

        const username = this.$store.getters['authentication/oidcUsername'];
        await this.$store.dispatch('users/updateUsersMe', {
          username: username,
        });

        await this.$store.dispatch('profile/createProfile', {
          profileName: username,
          displayName: username,
          preferredLocale: this.$i18n.locale,
        });

        await this.$store.dispatch('authentication/getMe');
      } else console.debug(contextualizedLogMessage('Registered user found.'));

      await this.$store.dispatch('authentication/getUserMe');
      await this.$store.dispatch('authentication/setProfileId');

      console.debug(contextualizedLogMessage('Finished register check'));

      return newlyRegistered;
    },
    /**
     * Removes, i.e. unsets the query parameters used by the OIDC callback.
     * @returns {void}
     */
    updateQueryParams() {
      console.debug(contextualizedLogMessage('Updating query params'));

      const savedParams = this.getAppLoadQueryParams();

      console.debug(
        contextualizedLogMessage(
          'Restoring saved params and overriding with route query params'
        ),
        savedParams
      );

      const queryParamsToSet = { ...savedParams, ...this.$route.query };

      if (Object.keys(queryParamsToSet).length === 0) {
        console.debug(
          contextualizedLogMessage(
            'There are no query parameters set. Not clearing or setting any query parameters'
          )
        );

        return;
      }

      delete queryParamsToSet.code;
      delete queryParamsToSet.state;
      delete queryParamsToSet.scope;
      delete queryParamsToSet.session_state;
      delete queryParamsToSet.redirectToLogin;
      delete queryParamsToSet.shouldRefreshAccessToken;
      delete queryParamsToSet.mobileApp;

      this.$router.replace({ query: queryParamsToSet });

      console.debug(
        contextualizedLogMessage(
          'Restored query params and cleared OIDC query params'
        ),
        queryParamsToSet
      );

      this.clearAppLoadQueryParams();
    },
    /**
     * Initialize the SignalR hubs.
     */
    registerSignalR() {
      console.debug(contextualizedLogMessage('Registering SignalR'));

      signalR(this.$nuxt.context);
    },
    /**
     * If the user is newly registered or hasn't completed the onboarding steps, redirect them to onboarding.
     * Returns whether the user should be redirected to onboarding.
     *
     * @function handleOnboardingRedirect
     * @param {Boolean} newlyRegistered Whether the user has just registered
     * @param {string} axeptioToken The axeptio token to set, if available
     * @returns {Boolean} Whether the user should be redirected to onboarding
     */
    handleOnboardingRedirect(newlyRegistered, axeptioToken) {
      // if already on onboarding, return false
      if (unlocalizeRouteName(this.$route) === appURLs.onboarding()) {
        console.debug(
          contextualizedLogMessage(
            'The user is already on onboarding. They should not be redirected to onboarding again'
          )
        );

        return false;
      }

      const hasCompletedOnboarding =
        this.$store.getters['onboarding/hasCompletedTutorial'] === true;

      const shouldRedirectToOnboarding =
        newlyRegistered || !hasCompletedOnboarding;

      console.debug(contextualizedLogMessage(`Onboarding completion status`), {
        newlyRegistered,
        hasCompletedOnboarding,
        shouldRedirectToOnboarding,
      });

      if (shouldRedirectToOnboarding) {
        console.debug(contextualizedLogMessage('Redirecting to onboarding.'));

        inAppRedirect(
          this.localePath({
            name: appURLs.onboarding(),
            query: {
              axeptio_token: axeptioToken,
            },
          })
        );
      }

      return shouldRedirectToOnboarding;
    },
    // TODO extract into separate file
    /**
     * Register event handlers for service-worker related functionalities.
     * @returns {void}
     */
    async setupSWHandlers() {
      try {
        console.debug(
          contextualizedLogMessage('Setting up service worker message handlers')
        );

        const wb = await window.$workbox;

        // TODO wait until service worker becomes available
        // TODO/NOTE - i've found inconsistent behavior between chrome and firefox when it came down to registering service workers
        // it seemed like on firefox the service worker wouldnt register sometimes
        if (!window.navigator.serviceWorker) {
          console.error(
            contextualizedLogMessage(
              'window.navigator.serviceWorker is unavailable. This is unexpected at this point.'
            )
          );

          return;
        }

        console.debug(
          contextualizedLogMessage('Registering serviceWorker message handler'),
          wb
        );

        window.navigator.serviceWorker.addEventListener(
          'message',
          async (event) => {
            console.debug(
              contextualizedLogMessage('Received service worker message'),
              event
            );

            const swEvent = event.data;

            if (!swEvent.type) {
              console.error(
                contextualizedLogMessage(
                  'No message type on service worker message. This is unexpected'
                )
              );

              return;
            }

            // this type should be carefully coordinated with skade-sw.js
            switch (swEvent.type) {
              default:
                console.error(
                  contextualizedLogMessage(
                    `Unhandled service worker message type ${swEvent.type}`
                  )
                );
            }
          }
        );
      } catch (ex) {
        console.error(
          contextualizedLogMessage('Failed to setup service worker handlers'),
          ex
        );
      }
    },
    /**
     * Sets up window/document/browser tab visibility handlers.
     * These visibility handlers are used for dealing with email confirmation checks and the necessary token refreshing that should follow.
     *
     * If a browser tab is visible, there should be a timed loop in which we check whether the user's email confirmation status matches that of the token.
     * If they do not match and its the token that is inconsistent, we should refresh the token.
     * If the tab is no longer visible, the loop for that tab should stop and restart when the tab gains focus.
     * Whenever we perform a check, we re-establish the email confirmation status in the auth provider and in the token.
     */
    async setupVisibilityHandlers() {
      console.debug(contextualizedLogMessage('Setting visibility handlers'));

      const documentHidden = () => document.visibilityState === 'hidden';
      const documentVisible = () => document.visibilityState === 'visible';

      // the logic to execute in a loop for the email confirmation
      const emailConfirmationLoop = async () => {
        // if the tab isnt visible, stop the loop
        if (documentHidden()) {
          console.debug(
            contextualizedLogMessage(
              'Tab is hidden. Stopping email confirmation'
            )
          );

          stopEmailConfirmationLoop();

          return;
        }

        // establish the auth provider email confirmation status
        await this.$store.dispatch('authentication/getUserMe');

        const authProviderEmailConfirmed = await this.$store.getters[
          'authentication/isEmailConfirmed'
        ];

        // establish the token email confirmation status
        const token = await getAccessToken();
        const tokenEmailConfirmed = decodeJWT(token).ec === true;

        // check for mismatch
        const noMistmatch = authProviderEmailConfirmed === tokenEmailConfirmed;

        console.debug(contextualizedLogMessage('Email confirmation status'), {
          authProviderEmailConfirmed,
          tokenEmailConfirmed,
          noMistmatch,
        });

        // handle each case accordingly

        // if there's no mismatch and both are true, then the email is confirmed and the access token is up to date, so stop the loop.
        if (noMistmatch && tokenEmailConfirmed) {
          console.debug(
            contextualizedLogMessage(
              'No mismatch and token email is confirmed. Stopping loop'
            )
          );

          stopEmailConfirmationLoop();
        }
        // if there is a mismatch and it's only the token that isn't up to date with the auth provider, initiate a refresh and stop the loop.
        else if (!noMistmatch && !tokenEmailConfirmed) {
          console.debug(
            contextualizedLogMessage(
              "There is a mismatch and the access token's ec claim is false. Initiating refresh"
            )
          );

          await refreshSession();

          console.debug(
            contextualizedLogMessage(
              'Refreshed access token. Email confirmation status should be consolidated now. Stopping email confirmation loop'
            )
          );

          stopEmailConfirmationLoop();
        }
        // do not handle other cases as we can't do anything about them
        else {
          console.debug(
            contextualizedLogMessage(
              'No actions to take in email confirmation loop'
            )
          );
        }
      };

      const getEmailConfirmationInterval = () =>
        window.emailConfirmationLoopInterval;

      const setEmailConfirmationInterval = (interval) =>
        (window.emailConfirmationLoopInterval = interval);

      const startEmailConfirmationLoop = () => {
        console.debug(
          contextualizedLogMessage('Starting email confirmation loop')
        );

        const interval = getEmailConfirmationInterval();
        if (interval) stopEmailConfirmationLoop();

        // TODO extract timeout to constant
        emailConfirmationLoop();
        setEmailConfirmationInterval(setInterval(emailConfirmationLoop, 2000));
      };

      const stopEmailConfirmationLoop = () => {
        console.debug(
          contextualizedLogMessage('Stopping email confirmation loop')
        );

        const interval = getEmailConfirmationInterval();
        if (interval) {
          console.debug(
            contextualizedLogMessage('Clearing email confirmation interval')
          );

          clearInterval(interval);
          setEmailConfirmationInterval(undefined);
        } else {
          console.debug(
            contextualizedLogMessage('No email confirmation interval to clear')
          );
        }
      };

      // visibility event handlers
      document.addEventListener('visibilitychange', async () => {
        // if the current tab cannot be seen, the email confirmation loop should be stopped
        if (documentHidden()) {
          console.debug(
            contextualizedLogMessage('Visibility changed. Document hidden')
          );

          stopEmailConfirmationLoop();
        }
        // when the current tab is/becomes visible, start the email confirmation loop
        else if (documentVisible()) {
          console.debug(
            contextualizedLogMessage('Visibility changed. Document visible')
          );

          startEmailConfirmationLoop();
        } else {
          console.error(
            contextualizedLogMessage('Unknown document visibility state')
          );
        }
      });

      // start the loop once the app starts
      startEmailConfirmationLoop();
    },
    // Redirects the user to the auth provider welcome page
    redirectToWelcome() {
      window.location.href = `${process.env.NUXT_ENV_API_OIDC}/welcome/index`;
    },
  },
};
