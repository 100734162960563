import * as getters from './getters';

import actions from './actions';
import mutations from './mutations';

const state = () => ({
  trendingTickers: [],
  trendingLosersTickers: null,
  trendingGainersTickers: null,
  trendingTickersError: null,
  trendingTickersLoading: false,
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
