import { render, staticRenderFns } from "./PortfolioStatsCard.vue?vue&type=template&id=1d9cd859"
import script from "./PortfolioStatsCard.vue?vue&type=script&lang=js"
export * from "./PortfolioStatsCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InfoIcon: require('/skade-pwa/components/ui/atom/InfoIcon.vue').default,Card: require('/skade-pwa/components/ui/atom/Card.vue').default})
