export default {
  SET_DECODED_TOKEN(state, data) {
    state.decodedToken = data;
  },
  SET_LOGIN_ERROR: (state, data) => {
    state.loginError = data;
  },
  SET_REGISTER_ERROR: (state, data) => {
    state.registerError = data;
  },
  SET_ME(state, me) {
    state.me = me;
  },
  SET_USER_ME(state, userMe) {
    state.me.email = userMe.email;
    state.me.birthdate = userMe.birthdateUtc;
    state.isEmailConfirmed = userMe.emailConfirmed;
    // Other props are provided by the API
  },
  SET_PROFILE_ID(state, profileId) {
    if (state.me && state.me.profiles.length) {
      const profile = state.me.profiles.find((e) => e.profileId === profileId);

      if (profile) {
        state.profile = profile;
        state.profileId = profile.profileId;

        this.$api.setProfileId(profile.profileId);
        localStorage.setItem('profileId', profile.profileId);
      }
    }
  },
  SET_PROFILE(state, profile) {
    state.profile = profile;
  },

  SHOW_REGISTER(state) {
    state.showLogin = false;
    setTimeout(() => {
      state.showRegister = true;
    }, 250);
  },
  SHOW_LOGIN(state) {
    state.showRegister = false;
    setTimeout(() => {
      state.showLogin = true;
    }, 250);
  },
  SET_UPLOAD_PROFILE_AVATAR_LOADING(state, data) {
    state.uploadProfileAvatarLoading = data;
  },
  SET_UPLOAD_PROFILE_AVATAR_ERROR(state, data) {
    state.uploadProfileAvatarError = data;
  },
  SET_UPLOAD_PROFILE_AVATAR_SUCCESS(state, data) {
    state.uploadProfileAvatarSuccess = data;
    state.profile.avatarUri = null;
    state.profile.avatarUri = data.avatarUri;
  },
  SET_UPLOAD_PROFILE_AVATAR_INIT(state) {
    state.uploadProfileAvatarSuccess = null;
    state.uploadProfileAvatarError = null;
    state.uploadProfileAvatarLoading = true;
  },
  SET_UPLOAD_PROFILE_HEADER_LOADING(state, data) {
    state.uploadProfileHeaderLoading = data;
  },
  SET_UPLOAD_PROFILE_HEADER_ERROR(state, data) {
    state.uploadProfileHeaderError = data;
  },
  SET_UPLOAD_PROFILE_HEADER_SUCCESS(state, data) {
    state.uploadProfileHeaderSuccess = data;
    state.profile.headerUri = data.headerUri;
  },
  SET_UPLOAD_PROFILE_HEADER_INIT(state) {
    state.uploadProfileHeaderSuccess = null;
    state.uploadProfileHeaderError = null;
    state.uploadProfileHeaderLoading = true;
  },
  SET_LOGIN_USERNAME(state, value) {
    state.loginUsername = value;
  },
  SET_LOGIN_PROFILE_ID(state, value) {
    state.loginProfileId = value;
  },
  SET_EMAIL_CONFIRMED(state, value) {
    state.decodedToken.ec = value;
  },
  SET_IS_EMAIL_CONFIRMED(state, isConfirmed) {
    state.isEmailConfirmed = isConfirmed;
  },
  SET_USER_BIRTHDATE(state, birthdate) {
    state.me.birthdate = birthdate;
  },
  SET_NEWS_SUSCRIBED_LANGUAGES(state, value) {
    state.newsSuscribedLanguages = value;
  },
  SET_UPDATE_PROFILE_LOADING(state, value) {
    state.updateProfileLoading = value;
  },
  SET_UPDATE_PROFILE_ERROR(state, value) {
    state.updateProfileError = value;
  },
  UPDATE_FOLLOWING_COUNT(state, value) {
    state.profile.followingCount += value;
  },
  SET_SHOULD_REFRESH_ACCESS_TOKEN(state, value) {
    state.shouldRefreshAccessToken = value;
  },
  //TODO : REVERT
  VALIDATE_UPDATE_NEWS(state) {
    state.profile.hasSeenUpdateNews = true;
  },
  SET_PROFILE_HAS_SEEN_FEED_WELCOME_CARD(state, value) {
    state.profile.hasSeenFeedWelcomeCard = value;
  },
  DELETE_USER_INIT: (state) => {
    state.deleteUser = { data: null, loading: true, error: null };
  },
  DELETE_USER_SUCCESS: (state) => {
    state.deleteUser = { data: true, loading: false, error: null };
  },
  DELETE_USER_ERROR: (state, err) => {
    state.deleteUser = { data: null, loading: false, error: err };
  },
  SET_SEEN_PORTFOLIO_DRAWER: (state) => {
    if (state.profile) {
      state.profile.hasSeenPortfolioGuideDrawer = true;
    }
  },
};
