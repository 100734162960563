
import { mapActions } from 'vuex';
import { isMobileResponsive } from '~/utils/mixins';
import TruncateParagraph from '@/components/ui/atom/TruncateParagraph';
import { generateUID } from '@/utils';
import { appURLs } from '@/utils/route-redirects';

export default {
  name: 'Poll',
  components: {
    TruncateParagraph,
  },
  mixins: [isMobileResponsive],
  props: {
    pollId: {
      type: String,
      default: null,
    },
    mentions: {
      type: Array,
      default: () => [],
    },
    handleMouseEnterTitleMention: {
      type: Function,
      default: () => {
        return;
      },
    },
    handleMouseOutTitleMention: {
      type: Function,
      default: () => {
        return;
      },
    },
  },
  data() {
    return {
      pollDefinition: {
        title: '',
        options: [],
      },
      selectedOption: null,
      hasVoted: false,
      showMessageAfterVoting: false,
      userVotedOptionId: null,
      htmlTitle: '',
      mentionIds: [],
    };
  },
  computed: {
    poll() {
      return this.$store.state.post.polls[this.pollId] || [];
    },
    totalVotes() {
      return this.pollDefinition.options.reduce(
        (sum, option) => sum + option.votes,
        0
      );
    },
    pollWithPercentage() {
      if (
        !this.pollDefinition?.options ||
        this.pollDefinition.options.length === 0
      ) {
        return [];
      }

      const mostVotedOptionIds = this.getOptionIdWithMostVotes();

      return this.pollDefinition.options.map((option) => {
        const percentage = this.totalVotes
          ? (option.votes / this.totalVotes) * 100
          : 0;
        return {
          ...option,
          percentage: percentage.toFixed(0),
          isMostVoted: mostVotedOptionIds.includes(option.id),
        };
      });
    },
  },
  async mounted() {
    if (this.pollId) {
      try {
        await this.getPoll({ id: this.pollId });
        const votedResult = await this.getPollResults({ id: this.pollId });
        this.hasVoted = votedResult;

        const currentPoll = this.poll?.filter((p) => p.pollId === this.pollId);
        this.pollDefinition = {
          // TODO Fix the assumption that the poll will have a title.
          // I keep getting errors that the title is actually null.
          title: currentPoll[0]?.title,
          options: currentPoll.map((p) => ({
            id: p.id,
            text: p.option,
            votes: p.votes,
          })),
        };
      } catch (error) {
        console.error('Error fetching poll data:', error);
      }
      this.buildHtmlTitle();
    }
  },
  beforeDestroy() {
    this.removeTitleEvent();
  },
  methods: {
    ...mapActions('post', ['getPoll', 'getPollResults', 'votePoll']),
    async submitVote() {
      if (this.selectedOption) {
        const option = this.pollDefinition.options.find(
          (p) => p.id === this.selectedOption
        );
        if (option) {
          await this.votePoll({ id: option.id });
          this.hasVoted = true;
          this.showMessageAfterVoting = true;
          this.userVotedOptionId = option.id;

          this.pollDefinition.options = this.pollDefinition.options.map(
            (opt) => {
              if (opt.id === option.id) {
                return { ...opt, votes: opt.votes + 1 };
              }
              return opt;
            }
          );

          this.$forceUpdate();
        }
      }
    },
    getPercentage(votes) {
      return this.totalVotes ? ((votes / this.totalVotes) * 100).toFixed(0) : 0;
    },
    getOptionIdWithMostVotes() {
      const highestVotes = Math.max(
        ...this.pollDefinition.options.map((option) => option.votes)
      );
      return this.pollDefinition.options
        .filter((option) => option.votes === highestVotes)
        .map((option) => option.id);
    },
    buildHtmlTitle() {
      this.htmlTitle = this.pollDefinition.title;
      if (this.htmlTitle && this.mentions && this.mentions.length) {
        this.mentions.forEach((mention) => {
          if (mention.type === '@') {
            const id = generateUID();
            const profilePath = `/${appURLs.profile()}/${
              mention.profile.profileId
            }`;
            this.htmlTitle = this.htmlTitle.replaceAll(
              `${mention.type}${mention.profile.displayName}`,
              `<span id="${id}" class="${id} mention cursor-pointer" data-id="${mention.profile.profileId}"><a data-id="${mention.profile.profileId}" href="${profilePath}">${mention.type}${mention.profile.displayName}</a></span>`
            );
            this.mentionIds.push(id);
          } else if (mention.type === '#') {
            this.htmlTitle = this.htmlTitle.replaceAll(
              `${mention.type}${mention.target}`,
              `<a href="/search-hashtags?hashtag=${mention.target}" class="mention cursor-pointer">${mention.type}${mention.target}</a>`
            );
          } else if (mention.type === 'POST') {
            this.mentionedPost = mention.post;
          }
        });
      }
      setTimeout(() => {
        this.bindTitleEvent();
      }, 100);
    },
    bindTitleEvent() {
      if (this.isMobileResponsive) {
        // We don't want mention mention event attached on mobile
        return;
      }
      this.mentionIds.forEach((id) => {
        const mentionNodes = document.getElementsByClassName(id);
        for (const node of mentionNodes) {
          node.addEventListener(
            'mouseenter',
            this.handleMouseEnterTitleMention
          );
          node.addEventListener('mouseout', this.handleMouseOutTitleMention);
          node.addEventListener('click', this.handleMouseOutTitleMention, true);
        }
      });
    },
    removeTitleEvent() {
      this.mentionIds.forEach((id) => {
        if (!this.$refs.body) return;
        const mentionNodes = this.$refs.body.getElementsByClassName(id);

        for (const node of mentionNodes) {
          node.removeEventListener(
            'mouseenter',
            this.handleMouseEnterTitleMention
          );
          node.removeEventListener('mouseout', this.handleMouseOutBodyMention);
          node.removeEventListener(
            'click',
            this.handleMouseOutBodyMention,
            true
          );
        }
      });
      this.mentionIds = [];
    },
  },
};
