import actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = () => ({
  notifications: null,
  hasMoreNotifications: false,
  notificationCount: null,
  notificationOffset: 1,
  loading: false,
  nextNotificationsLoading: false,
  error: null,
  nextNotificationsError: null,
  // Outdated
  // TODO : remove below
  // notifications: { data: null, loading: null, error: null },
  handleReadAllnotification: { data: null, loading: null, error: null },
  handleReadMultipleNotifications: { data: null, loading: null, error: null },
  handleReadNotification: { data: null, loading: null, error: null },
  removeNotification: { data: null, loading: null, error: null },
  notificationsCount: { data: null, loading: null, error: null },
  notificationTypes: { data: null, loading: null, error: null },
  pushNotificationSettings: { data: null, loading: null, error: null },
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
