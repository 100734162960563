import { render, staticRenderFns } from "./PollVote.vue?vue&type=template&id=200c314e&scoped=true"
import script from "./PollVote.vue?vue&type=script&lang=js"
export * from "./PollVote.vue?vue&type=script&lang=js"
import style0 from "./PollVote.vue?vue&type=style&index=0&id=200c314e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "200c314e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TruncateParagraph: require('/skade-pwa/components/ui/atom/TruncateParagraph.vue').default,Button: require('/skade-pwa/components/ui/atom/buttons/Button.vue').default})
