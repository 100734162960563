import * as getters from './getters';

import actions from './actions';
import mutations from './mutations';

const state = () => ({
  createPortfolio: { data: null, loading: null, error: null },
  orderPortfolio: { data: null, loading: null, error: null },
  getPortfolioById: { data: null, loading: null, error: null },
  deletePortfolio: { data: null, loading: null, error: null },
  updatePortfolio: { data: null, loading: null, error: null },
  getPortfolioGraph: { data: null, loading: null, error: null },
  getMyPortfolios: { data: null, loading: null, error: null },
  getProfilePortfolios: { data: null, loading: null, error: null },
  createOperation: { data: null, loading: null, error: null },
  createCashOperation: { data: null, loading: null, error: null },
  addPortfolioItem: { data: null, loading: null, error: null },
  removePortfolioItem: { data: null, loading: null, error: null },
  removePortfolioCashItem: { data: null, loading: null, error: null },
  tickerToAdd: { data: null, loading: null, error: null },
  getTickerStockValue: { data: null, loading: null, error: null },
  getSimilarTickers: { data: null, loading: null, error: null },
  getTickerStockRangeValue: { data: null, loading: null, error: null },
  getTickerStockRangeWithOverall: { data: null, loading: null, error: null },
  getMultipleTickerStockRangeValue: { data: null, loading: null, error: null },
  getMultipleTickerStockRangeValueWithOverall: {
    data: null,
    loading: null,
    error: null,
  },
  getTeamPortfolioProfilesValue: { data: null, loading: null, error: null },
  updateTeamPortfolioProfileValue: { data: null, loading: null, error: null },
  deleteTeamPortfolioProfileValue: { data: null, loading: null, error: null },
  createTeamPortfolioProfileValue: { data: null, loading: null, error: null },
  isFavoritePortfolio: false,
  myFavoritePortfolios: { data: null, loading: null, error: null },
  portfolioTopGainers: { data: null, loading: null, error: null },
  monthlyPortfolioTopGainers: { data: null, loading: null, error: null },
  profilePortfolios: { data: null, loading: null, error: null },
  portfolioSearch: { data: null, loading: null, error: null },
  portfoliosToCompare: { data: null, loading: null, error: null },
  getPortfolioComparisonGraphInfo: { data: null, loading: null, error: null },
  portfolioItemsGainByIntervals: { data: null, loading: null, error: null },
  replies: null,
  repliesLoading: false,
  nextRepliesLoading: false,
  nestedRepliesLoading: false,
  nextNestedRepliesLoading: false,
  postError: null,
  repliesError: null,
  isLoadFinished: false,
  deletePortfolioComment: { data: null, loading: null, error: null },
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
