
import HeartIcon from '~/components/ui/icons/HeartIcon.vue';
import CommentIcon from '~/components/ui/icons/CommentIcon.vue';
import RepostIcon from '~/components/ui/icons/RepostIcon.vue';
import ShareIcon from '~/components/ui/icons/ShareIcon.vue';
import { isMobileResponsive } from '~/utils/mixins';

export default {
  name: 'SocialInteraction',
  components: {
    HeartIcon,
    CommentIcon,
    RepostIcon,
    ShareIcon,
  },
  mixins: [isMobileResponsive],
  props: {
    isLike: {
      type: Boolean,
      default: false,
    },
    likeCount: {
      type: Number,
      default: -1,
    },
    replyCount: {
      type: Number,
      default: -1,
    },
    repostCount: {
      type: Number,
      default: -1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showRepost: {
      type: Boolean,
      default: true,
    },
    showShare: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['onLike', 'onReply', 'onRepost', 'onShare'],
  computed: {
    shouldShowPostStats() {
      return this.replyCount > 0 && this.reportCount !== 0;
    },
  },
};
