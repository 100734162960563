import { CurrencyIdentifier } from '~/types/models/currency';
import * as disp from '.';
import { deltaPrefix } from './util';

const CURRENCY_SYMBOLS: {
  [key in CurrencyIdentifier]?: string;
} = {
  [CurrencyIdentifier.CNY]: `CN¥`,
  [CurrencyIdentifier.EUR]: `€`,
  [CurrencyIdentifier.GBP]: `£`,
  [CurrencyIdentifier.INR]: `₹`,
  [CurrencyIdentifier.JPY]: `JP¥`,
  [CurrencyIdentifier.KRW]: `₩`,
  [CurrencyIdentifier.RUB]: `₽`,
  [CurrencyIdentifier.USD]: `$`,
  [CurrencyIdentifier.CHF]: '₣',
};

/**
 * Displays the given amount of money as a human-readable string.
 * (OLD VERSION)
 *
 * @param value - The number to display.
 * @param currency - The currency to use for rendering.
 * @param minDecimals - The minimum number of decimals to display. Avoid using
 *   so as to have a uniform experience across the app. You are only guaranteed
 *   to have a **minimum number of digits** as specified.
 * @param maxDecimals - The maximum number of decimals to display. Avoid using.
 * @returns The human readable string.
 */
export function moneyOld(
  value: number,
  currency: CurrencyIdentifier,
  minDecimals: number | undefined = 2,
  maxDecimals: number | undefined = 2
): string {
  const stringified = disp.number.number(value, minDecimals, maxDecimals);
  return window.$nuxt.$t('money_format', {
    value: stringified,
    currency: CURRENCY_SYMBOLS[currency] || currency.toString(),
  }) as string;
}

/**
 * Displays the given amount of money without currency symbol as a human-readable string.
 * (OLD VERSION)
 *
 * @param value - The number to display.
 * @param minDecimals - The minimum number of decimals to display. Avoid using
 *   so as to have a uniform experience across the app. You are only guaranteed
 *   to have a **minimum number of digits** as specified.
 * @param maxDecimals - The maximum number of decimals to display. Avoid using.
 * @returns The human readable string.
 */
export function moneyOldWithoutCurrency(
  value: number,
  minDecimals: number | undefined = 2,
  maxDecimals: number | undefined = 2
): string {
  const stringified = disp.number.number(value, minDecimals, maxDecimals);
  return window.$nuxt.$t('money_format_without_currency', {
    value: stringified,
  }) as string;
}

/**
 * Displays the given amount of money as a human-readable string.
 * (NEW VERSION) Includes M and B to simplify Millions & Billions
 * WARNING - Do not use that in forms or for any sort of editable number, as it'll get altered - For display only!
 *
 * @param value - The number to display.
 * @param currency - The currency to use for rendering.
 * @param minDecimals - The minimum number of decimals to display. Avoid using
 *   so as to have a uniform experience across the app. You are only guaranteed
 *   to have a **minimum number of digits** as specified.
 * @param maxDecimals - The maximum number of decimals to display. Avoid using.
 * @param humanizeForMobile - For cases where we don't want to humanize given amount of money on mobile
 * @param millionThreshold - The numeric threshold at which the value should be formatted into millions (default: 1,000,000).
 * @returns The human readable string.
 */
export function money(
  value: number,
  currency: CurrencyIdentifier,
  minDecimals: number | undefined = 2,
  maxDecimals: number | undefined = 2,
  humanizeForMobile: boolean | undefined = true,
  millionThreshold: number | undefined = 1000000
): string {
  return window.innerWidth <= 1080
    ? humanizeForMobile
      ? humanizedAmount(value, currency, millionThreshold)
      : moneyOld(value, currency, minDecimals, maxDecimals)
    : moneyOld(value, currency, minDecimals, maxDecimals);
}

/**
 * Displays the given amount of money without currency as a human-readable string.
 * (NEW VERSION) Includes M and B to simplify Millions & Billions
 * WARNING - Do not use that in forms or for any sort of editable number, as it'll get altered - For display only!
 *
 * @param value - The number to display.
 * @param currency - The currency to use for rendering.
 * @param minDecimals - The minimum number of decimals to display. Avoid using
 *   so as to have a uniform experience across the app. You are only guaranteed
 *   to have a **minimum number of digits** as specified.
 * @param maxDecimals - The maximum number of decimals to display. Avoid using.
 * @returns The human readable string.
 */
export function moneyWithoutCurrency(
  value: number,
  minDecimals: number | undefined = 2,
  maxDecimals: number | undefined = 2
): string {
  return window.innerWidth <= 1080
    ? humanizedAmountWithoutCurrency(value)
    : moneyOldWithoutCurrency(value, minDecimals, maxDecimals);
}

/**
 * Displays the given amount of money as a human-readable string.
 *
 *
 * @param value - The number to display.
 * @param currency - The currency to use for rendering.
 * @returns The human readable string.
 */
export function humanizedAmount(
  value: number,
  currency: CurrencyIdentifier,
  millionThreshold: number | undefined = 1000000
): string {
  const stringified = disp.number.readableNumber(value, millionThreshold);
  return window.$nuxt.$t('money_format', {
    value: stringified,
    currency: CURRENCY_SYMBOLS[currency] || currency.toString(),
  }) as string;
}

/**
 * Displays the given amount of money without currency symbol as a human-readable string.
 *
 *
 * @param value - The number to display.
 * @returns The human readable string.
 */
export function humanizedAmountWithoutCurrency(value: number): string {
  const stringified = disp.number.readableNumber(value);
  return window.$nuxt.$t('money_format_without_currency', {
    value: stringified,
  }) as string;
}

export function getAvailableCurrencies() {
  return [
    { name: 'EUR', symbol: CURRENCY_SYMBOLS['EUR'] },
    { name: 'USD', symbol: CURRENCY_SYMBOLS['USD'] },
    { name: 'GBP', symbol: CURRENCY_SYMBOLS['GBP'] },
    { name: 'CHF', symbol: CURRENCY_SYMBOLS['CHF'] },
  ];
}

export function getAvailableCurrencyNames() {
  return ['EUR', 'USD', 'GBP', 'CHF'];
}

export function getCurrencySymbol(currencyCode: CurrencyIdentifier) {
  if (currencyCode in CURRENCY_SYMBOLS) {
    return CURRENCY_SYMBOLS[currencyCode];
  }
  return '';
}

/**
 * Displays the given amount of money as a human-readable string.
 *
 * @param value - The number to display.
 * @param currency - The currency to use for rendering.
 * @param minDecimals - The minimum number of decimals to display. Avoid using
 *   so as to have a uniform experience across the app. You are only guaranteed
 *   to have a **minimum number of digits** as specified.
 * @param maxDecimals - The maximum number of decimals to display. Avoid using.
 * @returns The human readable string.
 */
export function delta(
  value: number,
  currency: CurrencyIdentifier,
  minDecimals: number | undefined = 2,
  maxDecimals: number | undefined = 2
): string {
  const stringified = disp.number.delta(value, minDecimals, maxDecimals, true);
  return (deltaPrefix(value) +
    window.$nuxt.$t('money_format', {
      value: stringified,
      currency: CURRENCY_SYMBOLS[currency] || currency.toString(),
    })) as string;
}
