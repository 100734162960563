
import { getCompanyTagText } from '@/utils/company-tags.ts';
export default {
  name: 'CompanyTagCard',
  props: {
    companyType: {
      type: String,
      default: '',
    },
  },
  methods: {
    getCompanyTagStyle() {
      let toReturn = '';
      switch (this.companyType) {
        case 'Stock':
          toReturn =
            'background: linear-gradient(270deg, #BFF9FB 0%, #D2D9FB 100%);';
          break;
        case 'Index':
          toReturn =
            'background: linear-gradient(90deg, #D1DBFB 0%, #F8DB92 100%);';
          break;
        case 'ETF':
          toReturn =
            'background: linear-gradient(90deg, #D2DAFB 0%, #DCBEE1 100%);';
          break;
        case 'Cryptocurrency':
          toReturn =
            'background: linear-gradient(90deg, #D2DAFB 0%, #C1FCB2 100%);';
          break;
        default:
          break;
      }
      return toReturn;
    },
    getCompanyTag() {
      return getCompanyTagText(this.companyType);
    },
  },
};
