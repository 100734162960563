
import UITrendingStockWidget from '~/components/ui/widgets/symbol/UITrendingStockWidget.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  Name: 'TrendingGainersTickersWidgetWrapper',
  components: { UITrendingStockWidget },
  props: {
    limit: {
      type: Number,
      default: 5,
    },
  },
  async fetch() {
    if (process.client) {
      if (!this.trendingGainersTickers) {
        await this.getTrendingTickers({
          limit: this.limit,
          trendingType: 'GAINERS',
          type: 0,
        });
      }
    }
  },
  computed: {
    ...mapGetters('trendingTickersWidget', [
      'trendingGainersTickers',
      'trendingTickersLoading',
    ]),
    tickersList() {
      return this.trendingGainersTickers;
    },
  },
  methods: {
    ...mapActions('trendingTickersWidget', ['getTrendingTickers']),
  },
};
