
import { mapActions, mapGetters } from 'vuex';
import UICreatePostModal from '@/components/ui/organism/UICreatePostModal';
import UICreatePostMobileModal from '@/components/ui/organism/UICreatePostMobileModal';
import UICreateReplyModal from '@/components/ui/organism/UICreateReplyModal';
import {
  uploadPostImage,
  searchMentions,
  searchHashtags,
} from '@/utils/mixins';
import { showToast, showErrorToast } from '~/utils/toast-notifications';
import { isMobile } from '~/utils/screenSizes';
import { appURLs } from '@/utils/route-redirects';

export default {
  name: 'CreatePostModalWrapper',
  components: {
    UICreatePostModal,
    UICreateReplyModal,
    UICreatePostMobileModal,
  },
  mixins: [uploadPostImage, searchMentions, searchHashtags],
  data() {
    return {
      clear: false,
    };
  },
  computed: {
    ...mapGetters('profile', ['profile']),
    ...mapGetters('authentication', ['profile', 'isEmailConfirmed']),
    ...mapGetters('modals', ['showCreatePostModalRes']),
    ...mapGetters('api', ['createPostRes']),
    ...mapGetters({
      updatedPost: 'post/post',
    }),
    ...mapGetters('post', ['replies']),
    avatarUri() {
      if (this.profile && this.profile.avatarUri) {
        return this.profile.avatarUri;
      }
      return '';
    },
    displayName() {
      if (this.profile && this.profile.displayName) {
        return this.profile.displayName;
      }
      return '';
    },
    isMobileSize() {
      return isMobile();
    },
  },
  methods: {
    ...mapActions('api', ['createPost', 'updatePost']),
    ...mapActions('modals', ['showCreatePostModal']),
    ...mapActions('post', { postAppendNewNestedReply: 'appendNewNestedReply' }),
    ...mapActions('home', { homeAppendNestedReplyTop: 'appendNestedReplyTop' }),
    ...mapActions('profile', ['getProfileById']),
    ...mapActions('hashtags', {
      hashtagsAppendNestedReplyTop: 'appendNestedReplyTop',
    }),
    ...mapActions('cryptoNews', {
      cryptoAppendNestedReplyTop: 'appendNestedReplyTop',
    }),
    ...mapActions('market', {
      marketAppendNestedReplyTop: 'appendNestedReplyTop',
    }),
    ...mapActions('profile', {
      profileAppendNestedReplyTop: 'appendNestedReplyTop',
    }),
    ...mapActions('portfolio', {
      appendNewPortfolioNestedReply: 'appendNewNestedReply',
      appendNewReply: 'appendNewReply',
    }),
    ...mapActions({
      appendToHomeFeed: 'home/appendNewPostAtTop',
      appendToProfileFeed: 'profile/appendNewPostAtTop',
    }),
    ...mapActions('post', ['getPost', 'getReplies']),
    ...mapActions({
      updatePostOnHomeFeed: 'home/updatePostAfterEdit',
      updateReplyOnHomeFeed: 'home/updateReply',
      updatePostOnProfileFeed: 'profile/updatePostAfterEdit',
      updateReplyOnProfileFeed: 'profile/updateReply',
      updatePostOnPostPage: 'post/updatePostAfterEdit',
      updateReplyOnPostPage: 'post/updateReply',
      updatePostOnMarketPage: 'market/updatePostAfterEdit',
      updateReplyOnMarketPage: 'market/updateReply',
      updateReplyOnPortfolioPage: 'portfolio/updateReply',
      updateReplyOnCompanyPage: 'companyProfile/updateReply',
      updateNestedReplyOnCompanyPage: 'companyProfile/updateNestedReply',
    }),
    onSubmit(data) {
      this.createPost(data)
        .then((res) => {
          if (this.showCreatePostModalRes.context) {
            if (this.showCreatePostModalRes.context.page == 'postDetail') {
              if (
                this.showCreatePostModalRes.context.action ==
                'createNestedReply'
              ) {
                this.postAppendNewNestedReply({
                  postId: this.showCreatePostModalRes.context.params.postId,
                  post: res.data,
                });
              }
            }
            if (
              this.showCreatePostModalRes.context.page == 'portfolioComments'
            ) {
              if (
                this.showCreatePostModalRes.context.action ==
                'appendNewPortfolioNestedReply'
              ) {
                this.appendNewPortfolioNestedReply({
                  postId: this.showCreatePostModalRes.context.params.postId,
                  post: res.data,
                });
              }
            }
            if (
              this.showCreatePostModalRes.context.page == 'portfolioDetails'
            ) {
              if (
                this.showCreatePostModalRes.context.action == 'appendNewReply'
              ) {
                this.appendNewReply(res.data);
              }
            }
            if (this.showCreatePostModalRes.context.page == 'home') {
              if (
                this.showCreatePostModalRes.context.action ==
                'homeAppendNestedReplyTop'
              ) {
                this.homeAppendNestedReplyTop({
                  data: res.data,
                  postId: this.showCreatePostModalRes.context.params.postId,
                });
              }
            }
            if (this.showCreatePostModalRes.context.page == 'crypto') {
              if (
                this.showCreatePostModalRes.context.action ==
                'cryptoAppendNestedReplyTop'
              ) {
                this.cryptoAppendNestedReplyTop({
                  data: res.data,
                  postId: this.showCreatePostModalRes.context.params.postId,
                });
              }
            }
            if (this.showCreatePostModalRes.context.page == 'market') {
              if (
                this.showCreatePostModalRes.context.action ==
                'marketAppendNestedReplyTop'
              ) {
                this.marketAppendNestedReplyTop({
                  data: res.data,
                  postId: this.showCreatePostModalRes.context.params.postId,
                });
              }
            }
            if (this.showCreatePostModalRes.context.page == 'profile') {
              if (
                this.showCreatePostModalRes.context.action ==
                'profileAppendNestedReplyTop'
              ) {
                this.profileAppendNestedReplyTop({
                  data: res.data,
                  postId: this.showCreatePostModalRes.context.params.postId,
                });
              }
            }
            if (this.showCreatePostModalRes.context.page == 'hashtags') {
              if (
                this.showCreatePostModalRes.context.action ==
                'hashtagsAppendNestedReplyTop'
              ) {
                this.hashtagsAppendNestedReplyTop({
                  data: res.data,
                  postId: this.showCreatePostModalRes.context.params.postId,
                });
              }
            }
          }
          if (res.data.targetType == 'PROFILE') {
            this.$route.name?.includes('profile') &&
              this.$route.path.includes(this.profile.profileId) &&
              this.appendToProfileFeed(res.data);
            this.appendToHomeFeed(res.data);
          }
          showToast(this.$t('post_created_ok'));

          this.showCreatePostModalRes.dispatch &&
            this.$store.dispatch(this.showCreatePostModalRes.dispatch);
          this.showCreatePostModal();

          this.clear = true;
          setTimeout(() => {
            this.clear = false;
          }, 100);
        })
        .catch((error) => {
          console.error(error);
          showErrorToast(this.$t('error_occured_message'));
        });
    },
    onUpdate(data) {
      this.updatePost(data)
        .then(async (res) => {
          this.handlePostUpdate(res.data, data);
        })
        .catch(() => this.$notify.error(this.$t('error_occured_message')));
    },
    async handlePostUpdate(postObject, data) {
      await this.getPost(data.postId);

      if (postObject) {
        const isProfileRoute = this.$route.fullPath.includes(appURLs.profile());
        const isHomeRoute = this.$route.name?.includes('index');
        const isPostRoute = this.$route.name?.includes('post');
        const isMarketRoute = this.$route.name?.includes('market');
        const isPortfolioRoute = this.$route.name?.includes('portfolios');

        if (this.updatedPost) {
          this.updatedPost.replies = data.replies ?? [];
          this.updatedPost.replyCount = data.replyCount ?? 0;

          if (isPortfolioRoute) {
            await this.getReplies({
              postId: data.postId,
              params: { limit: 10, orderBy: 'CreatedDateDescending' },
            });
            this.updateReplyOnPortfolioPage({
              postId: data.postId,
              data: this.updatedPost,
            });
          } else if (isMarketRoute) {
            await this.getReplies({
              postId: data.postId,
              params: { limit: 10, orderBy: 'CreatedDateDescending' },
            });
            this.updateReplyOnMarketPage({
              postId: data.postId,
              data: this.updatedPost,
            });
          } else if (data.targetType === 'PROFILE') {
            if (isProfileRoute) {
              this.updatePostOnProfileFeed(this.updatedPost);
            } else if (isHomeRoute) {
              this.updatePostOnHomeFeed(this.updatedPost);
            } else if (isPostRoute) {
              this.updatePostOnPostPage(this.updatedPost);
            }
          } else if (data.targetType === 'COMPANY') {
            this.updatedPost.target = data.postId;
            this.updateReplyOnCompanyPage({
              postId: data.target,
              data: this.updatedPost,
            });
          } else if (data.targetType === 'POST') {
            if (isProfileRoute) {
              if (this.profile.profileId !== this.$route.params.profileId) {
                this.updateNestedReplyOnCompanyPage({
                  postId: data.postId,
                  data: this.updatedPost,
                });
              } else {
                this.updatedPost.target = data.postId;
                this.updateReplyOnProfileFeed({
                  postId: data.target,
                  data: this.updatedPost,
                });
              }
            } else if (isHomeRoute) {
              this.updatedPost.target = data.postId;
              this.updateReplyOnHomeFeed({
                postId: data.target,
                data: this.updatedPost,
              });
            } else if (isPostRoute) {
              await this.getReplies({
                postId: data.postId,
                params: { limit: 10, orderBy: 'CreatedDateDescending' },
              });
              this.updateReplyOnPostPage({
                postId: data.postId,
                data: this.updatedPost,
              });
            }
          }

          this.showCreatePostModal();
        }
      }
    },
  },
};
