
import { mapActions, mapGetters } from 'vuex';
import { isMobileResponsive } from '~/utils/mixins';

export default {
  name: 'UserImgInfo',
  mixins: [isMobileResponsive],
  props: {
    img: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    profile: {
      type: Object,
      default: null,
    },
    info: {
      type: String,
      default: '',
    },
    isInfoProfileName: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    infoLight: {
      type: Boolean,
      default: false,
    },
    displayNameLight: {
      type: Boolean,
      default: false,
    },
    displayNameColorLight: {
      type: Boolean,
      default: false,
    },
    longTitle: {
      type: Boolean,
      default: false,
    },
    showProfileImg: {
      type: Boolean,
      default: true,
    },
    nameBold: {
      type: Boolean,
      default: false,
    },
    hasMentionCardEvent: {
      type: Boolean,
      default: true,
    },
    /**
     * @description Whether to use elipsis for the `name` or not
     * @type {Boolean}
     * @default false
     */
    useElipsis: {
      type: Boolean,
      default: false,
    },
    /**
     * @description Name max length. If left null, will use the dynamicMaxLength computed prop
     * @type {Number}
     * @default null
     */
    nameMaxLength: {
      type: Number,
      default: null,
    },
    portfolioCommentDetails: {
      type: Object,
      default: null,
    },
    companyCommentDetails: {
      type: Object,
      default: null,
    },
    isEditedByModerator: {
      type: Boolean,
      default: false,
    },
    isNewsPost: {
      type: Boolean,
      default: false,
    },
    displayNameSmall: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      screenWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters('modals', ['currentMentionCard']),
    nameClass() {
      let nclass = '';
      if (this.displayNameSmall) {
        nclass += 'text-fs-13-px';
      } else {
        nclass = this.displayNameLight ? 'text-fs-2' : 'text-fs-3';
      }

      nclass += this.nameBold ? ' text-fw-3' : ' text-fw-2';
      nclass += this.displayNameColorLight
        ? ' text-neutral-7'
        : ' text-neutral-8';
      return nclass;
    },
    dynamicMaxLength() {
      return this.longTitle ? 80 : this.screenWidth > 1024 ? 80 : 40;
    },
    isMobile() {
      return window.screen.width <= 640;
    },
  },
  watch: {
    $mq() {
      this.screenWidth = window.innerWidth;
    },
  },
  methods: {
    ...mapActions('modals', ['mouseEnterMention', 'mouseLeaveMention']),
    handleMouseEnter(elem) {
      if (this.profile !== null && !this.isMobile && this.hasMentionCardEvent) {
        const boundingBox = elem.getBoundingClientRect();
        const payload = {
          pos: {
            top: window.top.scrollY + boundingBox.bottom + 8, // + elem.offsetTop + (elem.offsetHeight + 8),
            left: boundingBox.x, // + elem.offsetLeft,
          },
          profile: this.profile,
          id: this.profile.profileId,
        };
        this.mouseEnterMention(payload);
      }
    },
    handleMouseLeave() {
      if (this.isMobile || !this.hasMentionCardEvent) {
        return;
      }
      this.mouseLeaveMention(this.profile.profileId);
    },
  },
};
