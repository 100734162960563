import * as getters from './getters';

import actions from './actions';
import mutations from './mutations';

const state = () => ({
  cursor: null,
  feed: null,
  newPostNumber: null,
  feedLoading: false,
  nextPostsLoading: false,
  error: null,
  deleteUserPost: { data: null, loading: false, error: null },
  forceFeedRefresh: false,
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
