
import { buildAssetUri } from '@/utils/mixins/';
import { PostDisabledReason } from '~/types/models/post';
import { debounce } from 'lodash';
import { ensureLinkPrefixed, linkRegex, htmlRegex } from '~/utils/links';
import Embed from '@/components/ui/molecule/Embed';
import { emitUpdateEmbed, initialEmbedState } from '~/utils/behavior/embed';
import { getInitials, generateAvatarStyle } from '~/utils/profile-avatars';
import { mentionRegex } from '~/utils/behavior/mentions';
import { _maxPostLength } from '@/utils/post-settings';

export default {
  name: 'Quill',
  components: {
    Embed,
  },
  mixins: [buildAssetUri],
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    searchMentions: {
      type: Function,
      required: true,
    },
    searchHashtags: {
      type: Function,
      required: true,
    },
    embedClasses: {
      type: String,
      default: '',
    },
    focusAtCreation: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    post: {
      type: Object,
      default: null,
    },
    /**
     * Use this as a replacement for focusAtCreation when the component is shown rather than created
     */
    focus: {
      type: Boolean,
      default: false,
    },
    openEditorPreventsScroll: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['change', 'toggleDisable', 'update-embed', 'getEditor'],
  data() {
    return {
      editor: null,
      showCharLeftDiv: false,
      charLeftCount: 0,
      defaultUri: require('@/assets/img/unknow-user.jpg'),
      showErrorMessage: false,
      embed: { ...initialEmbedState },
      links: [],
      debouncedHandleLinkChanges: debounce(this.handleLinkChanges, 500),
      mentionCount: 0,
    };
  },
  computed: {
    maxPostLength() {
      return _maxPostLength;
    },
  },
  watch: {
    focus() {
      if (this.focus)
        this.editor.focus({ preventScroll: this.openEditorPreventsScroll });
    },
  },
  mounted() {
    this.initClientOnlyQuill();
  },
  methods: {
    updateEmbed(embed) {
      this.embed = embed;

      emitUpdateEmbed(this, embed);
    },
    hideEmbed() {
      // the embed is initially hidden, so go back to that state
      this.updateEmbed(initialEmbedState);
    },
    initClientOnlyQuill(count = 10) {
      this.$nextTick(() => {
        if (this.$refs.Quill && !this.editor) {
          this.editor = new this.$quill(this.$refs.Quill, {
            placeholder:
              this.placeholder ||
              (this.isEdit ? this.$t('post_edit') : this.$t('start_post')),
            modules: {
              mention: {
                allowedChars: /^[\w]*$/,
                mentionDenotationChars: ['@', '#'],
                source: this.handleSource,
                renderItem: this.renderSearchItem,
              },
            },
          });

          // open links in new tab
          this.$refs.Quill.addEventListener('click', (e) => {
            if (e.target.tagName === 'A') {
              window.open(e.target.href, '_blank')?.focus();
            }
          });

          this.$emit('getEditor', this.editor);

          this.editor.on('text-change', async (_delta, _oldDelta, source) => {
            const delta = this.editor.getContents();
            let text = '';

            // Iterate through the Delta to process mentions
            delta.ops.forEach((op) => {
              if (op.insert) {
                if (typeof op.insert === 'object' && op.insert.mention) {
                  // Handle text representing mentions by occupying one empty space in the text var to
                  // compensate for Quill parsing mentions in strings as 1 character long
                  text += ` `;
                } else {
                  text += op.insert; // Add rest of text
                }
              }
            });
            const postLength = this.editor.getLength();
            if (text.match(htmlRegex)) {
              this.$emit('toggleDisable', {
                disabled: true,
                reason: PostDisabledReason.CONTENT,
              });
            } else if (postLength > _maxPostLength) {
              // ensure length is no more than max length
              this.$emit('toggleDisable', {
                disabled: true,
                reason: PostDisabledReason.LENGTH,
              });
              this.showErrorMessage = true;
            } else {
              this.showErrorMessage = false;
              this.$emit('toggleDisable', {
                disabled: false,
                reason: null,
              });
            }

            // ensure users are informed of the number of characters left
            if (postLength > 1000) {
              this.showCharLeftDiv = true;
              this.charLeftCount = Math.abs(_maxPostLength - postLength);
            } else {
              this.showCharLeftDiv = false;
            }
            await this.applyTemporaryMentionStyles(text, source);
            await this.debouncedHandleLinkChanges(text, source);
          });

          this.editor.on('selection-change', (range, oldRange) => {
            if (range === null && oldRange !== null) {
              this.$emit('onBlur');
            }
          });
        } else if (count > 0) {
          this.initClientOnlyQuill(count - 1);
        }

        if (this.focusAtCreation) {
          this.editor.focus({ preventScroll: this.openEditorPreventsScroll });
        }

        if (this.post) this.setBodyForPostUpdate(this.post);
      });
    },
    async handleLinkChanges(text, source) {
      // check if the change was made by the user. we don't want to react to programmatic changes
      if (source !== 'user') return;

      // the text that may contain a link
      const textWithLink = text;

      // get the links from the text
      const linkMatches = textWithLink.match(linkRegex);

      // if there are no links in the text, return
      if (!linkMatches) {
        this.hideEmbed();
        this.editor.formatText(0, text.length, 'link', false);
        return;
      }

      // if there are links in the text, prepare them by ensuring they are prefixed with http:// or https://
      const prepareLink = ensureLinkPrefixed;

      linkMatches.forEach((link) => {
        const index = text.indexOf(link);

        this.editor.deleteText(index, link.length);
        this.editor.insertText(index, link, 'link', prepareLink(link));
      });

      const preparedLinks = linkMatches.map(prepareLink);

      const firstLink = preparedLinks[0];

      let domain = null;
      try {
        domain = new URL(firstLink).hostname;
      } catch (e) {
        console.info('error', e);
      }

      // if the link is the same as the last one, return - no need to re-embed
      if (this.embed.url === firstLink) return;

      // if the link is different, delete the previous embed
      this.hideEmbed();

      const response = await this.$api.getLinkEmbedding({
        url: firstLink,
      });

      if (!response.data) return;

      const { title, description, favIcon, openGraphTags } = response.data;

      const ogTitle = Object.hasOwn(openGraphTags, 'og:title')
        ? openGraphTags['og:title']
        : null;
      const ogDescription =
        Object.hasOwn(openGraphTags, 'og:description') &&
        openGraphTags['og:description'];
      const ogImage = Object.hasOwn(openGraphTags, 'og:image')
        ? openGraphTags['og:image']
        : null;
      const ogSiteName = Object.hasOwn(openGraphTags, 'og:site_name')
        ? openGraphTags['og:site_name']
        : null;

      const newEmbed = {
        ...this.embed,
        url: firstLink,
        domain,
        title,
        description,
        favIcon,
        openGraphTags: {
          title: ogTitle || '',
          description: ogDescription || description || '',
          image: ogImage || '',
          siteName: ogSiteName || '',
        },
        shouldShow: true,
      };

      this.updateEmbed(newEmbed);
    },
    renderSearchItem(item) {
      const isImage = Boolean(item.avatarUri);
      const initials = getInitials(item.value);
      const uri = this.buildAssetUri(item.avatarUri, this.defaultUri);
      const style = generateAvatarStyle(item, uri, isImage);

      return `
      <div class="flex items-center py-1 px-1">
        ${
          item.char === '@'
            ? `<div class="vue-avatar--wrapper" style="${style}">
                ${isImage ? '' : `<span>${initials}</span>`}
              </div>
                <div class="flex flex-col ml-2">
                  <span class="text-fw-3 text-fs-2 text-neutral-8">${
                    item.displayName
                  }</span>
                  <span class="text-fs-1 text-neutral-7">${item.char}&nbsp;${
                item.value
              }</span>
              </div>`
            : `<span class="ml-2">${item.char}${item.value}</span>`
        }
      </div>
    `;
    },
    async handleSource(searchTerm, renderList, mentionChar) {
      let values = [{ id: 0, value: searchTerm }];

      if (mentionChar === '@') {
        values = [...(await this.searchMentions(searchTerm))];
      } else if (mentionChar === '#') {
        values = [...(await this.searchHashtags(searchTerm))];
        searchTerm &&
          values.unshift({ id: searchTerm, value: searchTerm, char: '#' });
      }

      renderList(values, searchTerm);
    },
    async applyTemporaryMentionStyles(text, source) {
      // check if the change was made by the user. we don't want to react to programmatic changes
      if (source !== 'user') return;

      // get all mentions in the text
      const matches = text.match(mentionRegex);
      if (!matches) return;

      // apply mention styles
      matches.forEach((mention) => {
        const mentionCharIndex = text.indexOf(mention);
        // keeping track of number of mentions
        this.updateMentionCount();

        // if the mention is not found, return
        if (mentionCharIndex === -1) return;

        // apply the mention style
        this.editor.formatText(
          mentionCharIndex,
          mention.length,
          'color',
          'blue'
        );
      });
    },
    updateMentionCount() {
      // count the number of spans with the mention class
      const mentionSpans = this.editor.root.querySelectorAll('span.mention');
      this.mentionCount = mentionSpans.length;
    },
    setBodyForPostUpdate(post) {
      if (!this.editor) return;
      const parts = post.body.match(/[^ \n]+| |\n/g);

      const htmlParts = [];
      parts.forEach((part) => {
        if (part.startsWith('@')) {
          const id = part.substring(1);
          const name =
            post.mentions.find((m) => m.target === id)?.profile.displayName ||
            id;
          const mention =
            ' ' +
            `<span class="mention" data-id="${id}" data-value="${name}" data-denotation-char="@">` +
            `<span contenteditable="false"><span class="ql-mention-denotation-char">@</span>${name}</span></span><span> </span>`;

          htmlParts.push({ key: '@', value: mention });
        } else if (part.startsWith('#')) {
          const tag = part.substring(1) || '';
          const mention =
            ' ' +
            `<span href="/search-hashtags?hashtag=${tag}"
            class="mention cursor-pointer" data-denotation-char="#" data-value="${tag}">#${tag}</span><span> </span>`;
          htmlParts.push({ key: '#', value: mention });
        } else {
          const text = part.replaceAll('\n', '<br>');
          htmlParts.push({ key: 'text', value: text });
          this.handleLinkChanges(part);
        }
      });
      const html = htmlParts.map((part) => part.value).join('');

      this.editor.clipboard.dangerouslyPasteHTML(html);
      this.editor.setSelection(this.editor.getLength(), 0);
    },
  },
};
