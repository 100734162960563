import actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = () => ({
  //================================================
  // Authentication
  //================================================
  register: { data: null, loading: false, error: null },
  sendValidationMail: { data: null, loading: false, error: null },
  getValidationMailStatus: { data: null, loading: false, error: null },

  //================================================
  // Reset Password
  //================================================
  resetPasswordWithOld: { data: null, loading: false, error: null },
  resetPasswordWithToken: { data: null, loading: false, error: null },
  sendResetPasswordTokenEmail: { data: null, loading: false, error: null },

  //================================================================
  // Tickers
  //================================================================
  getCryptoTickerStockValue: { data: null, loading: false, error: null },

  //================================================
  // Feed
  //================================================
  getFeed: { data: null, loading: false, error: null },
  countNewFeed: { data: null, loading: false, error: null },

  //================================================
  // Industry
  //================================================
  getIndustries: { data: null, loading: false, error: null },
  followIndustryToggle: { data: null, loading: false, error: null },

  //================================================
  // Messages
  //================================================
  getMessageNotificationsAll: { data: null, loading: false, error: null },
  markAsReadMessageNotificationsAll: {
    data: null,
    loading: false,
    error: null,
  },

  //================================================
  // News
  //================================================
  getNews: { data: null, loading: false, error: null },
  getCryptoNews: { data: null, loading: false, error: null },
  getNewsDetail: { data: null, loading: false, error: null },

  //================================================
  // Profile
  //================================================
  getProfileById: { data: null, loading: false, error: null },
  getSymbolById: { data: null, loading: false, error: null },
  createProfile: { data: null, loading: false, error: null },
  updateProfile: { data: null, loading: false, error: null },
  uploadProfileAvatar: { data: null, loading: false, error: null },
  uploadProfileHeader: { data: null, loading: false, error: null },
  validateProfileProfilename: { data: null, loading: false, error: null },
  getProfileIdFromName: { data: null, loading: false, error: null },
  publicValidateProfileProfilename: { data: null, loading: false, error: null },
  publicValidateUserEmail: { data: null, loading: false, error: null },
  publicGetUserFromProfileName: { data: null, loading: false, error: null },
  followProfile: { data: null, loading: false, error: null },
  followStockChange: { data: null, loading: false, error: null },
  followedIdustries: { data: null, loading: false, error: null },
  rateProfile: { data: null, loading: false, error: null },
  getProfileFollowers: { data: null, loading: false, error: null },
  getProfileFollowed: { data: null, loading: false, error: null },
  getCompanySymbols: { data: null, loading: false, error: null },
  getCompanySymbolsV2: { data: null, loading: false, error: null },
  getMultipleCompanySymbols: { data: null, loading: false, error: null },
  getCryptoSymbols: { data: null, loading: false, error: null },
  getPrimaryTickerFundamentals: { data: null, loading: false, error: null },
  getTickerFundamentals: { data: null, loading: false, error: null },
  seenWTPModal: { data: null, loading: false, error: null },
  seenPortfolioGuideDrawer: { data: null, loading: false, error: null },
  deleteProfile: { data: null, loading: false, error: null },
  getCompanyPortfolios: { data: null, loading: false, error: null },
  getCompanyProfileComments: { data: null, loading: false, error: null },
  getProfileFollowedCountPerType: { data: null, loading: false, error: null },

  //================================================
  // Users
  //================================================
  updateUsersMe: { data: null, loading: false, error: null },
  patchUserBirthdate: { data: null, loading: false, error: null },
  deleteUser: { data: null, loading: false, error: null },

  //================================================
  // Search
  //================================================
  search: { data: null, loading: false, error: null },
  searchHistory: { data: null, loading: false, error: null },

  //================================================
  // Widgets
  //================================================
  getTrendingTickers: { data: null, loading: false, error: null },
  getTrendingIndices: { data: null, loading: false, error: null },
  getAllTrendingIndices: { data: null, loading: false, error: null },

  //================================================
  // Portfolio
  //================================================
  createPortfolio: { data: null, loading: false, error: null },
  getPortfolioTopGainers: { data: null, loading: false, error: null },
  getPortfolioTopGainersFollowed: { data: null, loading: false, error: null },
  getPortfolioMonthlyTopGainers: { data: null, loading: false, error: null },
  portfoliosSearch: { data: null, loading: false, error: null },
  getPortfolioComparisonGraphInfo: { data: null, loading: false, error: null },
  orderPortfolio: { data: null, loading: false, error: null },
  getPortfolioById: { data: null, loading: false, error: null },
  removePortfolio: { data: null, loading: false, error: null },
  likePortfolio: { data: null, loading: false, error: null },
  toggleCompleteView: { data: null, loading: false, error: null },
  updatePortfolio: { data: null, loading: false, error: null },
  getPortfolioGraph: { data: null, loading: false, error: null },
  getMyPortfolios: { data: null, loading: false, error: null },
  getProfilePortfolios: { data: null, loading: false, error: null },
  createOperation: { data: null, loading: false, error: null },
  createCashOperation: { data: null, loading: false, error: null },
  addPortfolioItem: { data: null, loading: false, error: null },
  removePortfolioItem: { data: null, loading: false, error: null },
  removePortfolioCashItem: { data: null, loading: false, error: null },
  getTickerStockValue: { data: null, loading: false, error: null },
  getSimilarTickers: { data: null, loading: false, error: null },
  getTickerStockRangeValue: { data: null, loading: false, error: null },
  getTickerStockRangeWithOverall: { data: null, loading: false, error: null },
  getMultipleTickerStockRangeValue: { data: null, loading: false, error: null },
  getMultipleTickerStockRangeValueWithOverall: {
    data: null,
    loading: false,
    error: null,
  },
  getTeamPortfolioProfiles: { data: null, loading: false, error: null },
  updateTeamPortfolioProfile: { data: null, loading: false, error: null },
  deleteTeamPortfolioProfile: { data: null, loading: false, error: null },
  isFavoritePortfolio: { data: null, loading: null, error: null },
  createTeamPortfolioProfile: { data: null, loading: null, error: null },
  getMyFavoritePortfolios: { data: null, loading: null, error: null },
  getFavoritePortfolio: { data: null, loading: null, error: null },
  favoritePortfolio: { data: null, loading: null, error: null },
  getPortfolioComments: { data: null, loading: null, error: null },
  getPortfolioItemsGainForInterval: { data: null, loading: null, error: null },
  //================================================
  // Post
  //================================================
  getPostById: { data: null, loading: false, error: null },
  getPosts: { data: null, loading: false, error: null },
  getPostReplies: { data: null, loading: false, error: null },
  createPost: { data: null, loading: false, error: null },
  updatePost: { data: null, loading: false, error: null },
  uploadPostImage: { data: null, loading: false, error: null },
  getPostImageForPreview: { data: null, loading: false, error: null },
  uploadPortfolioImage: { data: null, loading: false, error: null },
  likePost: { data: null, loading: false, error: null },
  favoritePost: { data: null, loading: false, error: null },
  reportPost: { data: null, loading: false, error: null },
  getMyFavoritesPost: { data: null, loading: false, error: null },
  getProfilePosts: { data: null, loading: false, error: null },
  deleteUserPost: { data: null, loading: false, error: null },

  //================================================
  // Notifications
  //================================================
  notifications: { data: null, loading: false, error: null },
  handleReadAllnotification: { data: null, loading: false, error: null },
  handleReadMultipleNotifications: { data: null, loading: false, error: null },
  handleReadNotification: { data: null, loading: false, error: null },
  removeNotification: { data: null, loading: false, error: null },
  notificationsCount: { data: null, loading: false, error: null },
  notificationTypes: { data: null, loading: null, error: null },
  pushNotificationSettings: { data: null, loading: null, error: null },

  //================================================
  // Onboarding
  //================================================
  tutorialNextStep: { data: null, loading: false, error: null },
  completeTutorial: { data: null, loading: false, error: null },
  tipsNextStep: { data: null, loading: false, error: null },
  completeTips: { data: null, loading: false, error: null },
  getUserOnboardingQuestions: { data: null, loading: false, error: null },
  getUserOnboardingAnswersByProfileId: {
    data: null,
    loading: false,
    error: null,
  },

  //================================================
  // Recommendations
  //================================================
  getRecommendedProfiles: { data: null, loading: false, error: null },
  getRecommendedCompanies: { data: null, loading: false, error: null },
  getMutualFollowsRecommendation: { data: null, loading: false, error: null },
  getMutualIndustriesRecommendation: {
    data: null,
    loading: false,
    error: null,
  },
  getSimilarCompaniesRecommendationRes: {
    data: null,
    loading: false,
    error: null,
  },
  getCompanyFollowersRecommendationRes: {
    data: null,
    loading: false,
    error: null,
  },
  getTopInvestorsRes: { data: null, loading: false, error: null },

  //================================================
  // Languages
  //================================================
  getNewsSuscribedLanguagesRes: {
    data: null,
    loading: false,
    error: null,
  },

  //================================================
  // Leagues
  //================================================
  createAdminLeague: {
    data: null,
    loading: false,
    error: null,
  },
  searchLeagues: {
    data: null,
    loading: false,
    error: null,
  },
  getPendingProfileInvitations: {
    data: null,
    loading: false,
    error: null,
  },
  answerLeagueInvitation: {
    data: null,
    loading: false,
    error: null,
  },
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
