
// import UserImgInfo from '@/components/ui/molecule/UserImgInfo';
import { getMedia, isMobileResponsive } from '@/utils/mixins';
import { inAppRedirect } from '~/utils/route-redirects';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'UIDeletedPost',
  components: {
    // UserImgInfo,
  },
  mixins: [getMedia, isMobileResponsive],
  props: {
    createdDate: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    isReply: {
      type: Boolean,
      default: false,
    },
    disableHover: {
      type: Boolean,
      default: false,
    },
    showCard: {
      type: Boolean,
      default: true,
    },
    author: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      profileData: null,
    };
  },
  computed: {
    ...mapGetters('profile', ['profile']),
    fromNow() {
      const fromNow = this.$dayjs(this.createdDate)
        .subtract('1', 's')
        .startOf('s')
        .fromNow();
      return fromNow;
    },
  },
  watch: {
    // TODO - I'm commenting that out for now as the author's not here to talk about it
    // But I doubt that's the profile we want to use for the post's author
    // profile: {
    //   handler(value) {
    //     this.profileData = value;
    //   },
    //   immediate: true,
    // },
  },
  beforeCreate: function () {
    this.$options.components.UIPost =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('@/components/ui/organism/UIPost').default;
  },
  async created() {
    // await this.getProfileById(this.authorProfileId);
  },
  methods: {
    ...mapActions('profile', ['getProfileById']),
    goToPost(postId) {
      inAppRedirect(`/post/${postId}`);
    },
  },
};
