
import CustomDrawer from '@/components/ui/molecule/CustomDrawer';
import CloseIcon from '@/components/ui/icons/CloseIcon';
import CheckIcon from '../icons/CheckIcon.vue';
import { mapActions } from 'vuex';

export default {
  name: 'NotificationsMenuDrawer',
  components: { CustomDrawer, CloseIcon, CheckIcon },
  data() {
    return {
      // Used to trigger the animation in CustomDrawer component
      show: false,
    };
  },
  mounted() {
    this.show = true;
  },
  methods: {
    ...mapActions('notifications', ['markAllNotificationsAsRead']),
    ...mapActions('modals', ['toggleNotificationsCenterDrawerMenu']),
    handleCloseDrawer() {
      this.show = false;
      // Setting a timeout so that we have enough time for closing animation to end
      setTimeout(() => {
        this.$emit('closeDrawer');
        // Special handling for drawer used in index modals component since
        // drawer there is shown based on the state
        this.toggleNotificationsCenterDrawerMenu(false);
      }, 100);
    },
    handleReadAllNotifications() {
      this.markAllNotificationsAsRead();
      this.handleCloseDrawer();
    },
  },
};
