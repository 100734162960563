export const notifications = (state) => state.notifications;
export const hasMoreNotifications = (state) => state.hasMoreNotifications;
export const notificationCount = (state) => state.notificationCount;
export const notificationOffset = (state) => state.notificationOffset;
export const loading = (state) => state.loading;
export const nextNotificationsLoading = (state) =>
  state.nextNotificationsLoading;
export const error = (state) => state.error;
export const nextNotificationsError = (state) => state.nextNotificationsError;
export const notificationTypes = (state) => state.notificationTypes;
export const pushNotificationSettings = (state) =>
  state.pushNotificationSettings;
