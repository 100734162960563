export default ({ store }) => {
  window.addEventListener('message', function (event) {
    const port = event.ports[0];
    if (typeof port === 'undefined') return;

    port.onmessage = function (event) {
      const parsedData = JSON.parse(event.data);
      if (parsedData && parsedData.token)
        window.handleFirebaseToken(parsedData.token);
    };

    window.handleFirebaseToken = (token) =>
      store.dispatch('api/saveDeviceToken', token);
  });
};
