/**
 * Filter interval keys used across the application
 */
export const FILTER_INTERVAL_KEYS = {
  ALL_TIME: 'all_time',
  ONE_DAY: 'one_day',
  ONE_WEEK: 'one_week',
  TWO_WEEKS: 'two_weeks',
  ONE_MONTH: 'one_month',
  THREE_MONTH: 'three_month',
  SIX_MONTH: 'six_month',
  ONE_YEAR: 'one_year',
  FIVE_YEAR: 'five_year',
  YTD: 'YTD',
};
