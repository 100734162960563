var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.renderPopup)?_c('div',{class:'fixed ' +
    (_vm.isMobileResponsive ? 'pt-4 bg-neutral-3 ' : 'py-2 bg-neutral-1 '),style:({
    top: '60px',
    height: _vm.isMobileResponsive ? '100vh' : 'auto',
    width: _vm.isMobileResponsive ? '100vw' : '360px',
    left: _vm.isMobileResponsive ? '0' : 'inherit',
    boxShadow: _vm.isMobileResponsive
      ? 'none'
      : '1px 1px 16px 0px rgba(0, 0, 0, 0.08)',
    borderRadius: _vm.isMobileResponsive ? '0' : '8px',
    overflow: 'hidden' /* Hide scrollbars */,
  })},[_c('div',{staticClass:"w-full",style:(_vm.isMobileResponsive
        ? {
            overflowY: 'scroll',
            WebkitOverflowScrolling:
              'touch' /* Enables smooth scrolling on iOS */,
            height: '100%' /* Ensures full height scrolling */,
            paddingBottom: '120px',
          }
        : {})},[(_vm.showEmptySearchHistory)?[_c('div',{staticClass:"flex justify-center items-center px-4 w-full text-fs-1_5"},[_c('span',[_vm._v(_vm._s(_vm.$t('empty_search_history')))])])]:_vm._e(),_vm._v(" "),(_vm.showSearchHistory)?[_c('div',{staticClass:"flex justify-between items-center px-4 h-10"},[_c('div',{staticClass:"font-semibold text-neutral-7 uppercase text-fs-1_5",staticStyle:{"line-height":"13px"}},[_vm._v("\n          "+_vm._s(_vm.$t('recent'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"font-medium text-primary-6 cursor-pointer text-fs-1_5",staticStyle:{"line-height":"13px"},on:{"mousedown":function($event){$event.preventDefault();return _vm.deleteSearchHistory.apply(null, arguments)}}},[_vm._v("\n          "+_vm._s(_vm.$t('clear'))+"\n        ")])]),_vm._v(" "),_vm._l((_vm.history),function(searchHistoryEntry){return _c('div',{key:searchHistoryEntry.id,class:'flex px-4 h-10 cursor-pointer font-semibold text-black text-fs-1_5 transition-colors ease-in-out hover:bg-primary-1',staticStyle:{"line-height":"13px"}},[_c('NuxtLink',{staticClass:"w-full h-full",attrs:{"to":_vm.searchResultsByQueryURL(searchHistoryEntry.query)},nativeOn:{"click":function($event){_vm.$emit(
              'navigate',
              _vm.searchResultsByQueryURL(searchHistoryEntry.query)
            )},"mousedown":function($event){return _vm.$emit('navigate-mousedown')},"mouseup":function($event){return _vm.$emit('navigate-mouseup')}}},[_c('div',{staticClass:"flex items-center h-full truncate"},[_vm._v("\n            "+_vm._s(searchHistoryEntry.query)+"\n          ")])])],1)})]:_vm._e(),_vm._v(" "),(_vm.showEmptySearchResults)?[_c('div',{staticClass:"flex justify-center items-center text-fs-1_5"},[_vm._v("\n        "+_vm._s(_vm.$t('no_search_results'))+"\n      ")])]:_vm._e(),_vm._v(" "),(_vm.showSearchResults)?_c('div',{staticClass:"flex flex-col"},[_vm._l((_vm.searchResults?.companies?.slice(0, 5)),function(company){return _c('SearchResultEntry',{key:company.profileId,attrs:{"image-src":company.avatarUri,"default-image-src":_vm.defaultCompanyPictureUri,"title":company.profileName,"sub-title":'',"link":_vm.appURLs.profileById(company.profileId)},on:{"navigate":function($event){return _vm.$emit('navigate', $event)},"navigate-mousedown":function($event){return _vm.$emit('navigate-mousedown')},"navigate-mouseup":function($event){return _vm.$emit('navigate-mouseup')}}})}),_vm._v(" "),_vm._l((_vm.searchResults?.mentions?.slice(0, 5)),function(user){return _c('SearchResultEntry',{key:user.profileId,attrs:{"image-src":user.avatarUri ?? '',"default-image-src":_vm.defaultProfilePictureUri,"title":user.displayName,"sub-title":user.profileName ? '@' + user.profileName : '',"link":_vm.appURLs.profileById(user.profileId)},on:{"navigate":function($event){return _vm.$emit('navigate', $event)},"navigate-mousedown":function($event){return _vm.$emit('navigate-mousedown')},"navigate-mouseup":function($event){return _vm.$emit('navigate-mouseup')}},scopedSlots:_vm._u([(!user.avatarUri)?{key:"customImage",fn:function(){return [_c('Avatar',{attrs:{"username":user.displayName,"size":32,"color":'#FFFF',"custom-style":{
              'border-color': 'var(--color-neutral-6)',
              'border-width': '1px',
              'border-radius': '9999px',
            }}})]},proxy:true}:null],null,true)})}),_vm._v(" "),_vm._l((_vm.searchResults?.cryptos?.slice(0, 5)),function(crypto){return _c('SearchResultEntry',{key:crypto.profileId,attrs:{"image-src":crypto.avatarUri,"default-image-src":_vm.defaultCompanyPictureUri,"title":crypto.profileName,"sub-title":crypto.industry ?? '',"link":_vm.appURLs.profileById(crypto.profileId)},on:{"navigate":function($event){return _vm.$emit('navigate', $event)},"navigate-mousedown":function($event){return _vm.$emit('navigate-mousedown')},"navigate-mouseup":function($event){return _vm.$emit('navigate-mouseup')}}})}),_vm._v(" "),_vm._l((_vm.searchResults?.etfSymbols?.slice(0, 5)),function(etf){return _c('SearchResultEntry',{key:etf.symbolId,attrs:{"image-src":etf.avatarUri,"default-image-src":_vm.defaultCompanyPictureUri,"title":etf.companyName,"sub-title":etf.name ?? '',"link":_vm.appURLs.profileById(etf.companyId)},on:{"navigate":function($event){return _vm.$emit('navigate', $event)},"navigate-mousedown":function($event){return _vm.$emit('navigate-mousedown')},"navigate-mouseup":function($event){return _vm.$emit('navigate-mouseup')}}})}),_vm._v(" "),_c('div',{staticClass:"flex flex-col gap-2.5 justify-center items-center px-4",staticStyle:{"height":"54px"}},[_c('div',{staticClass:"flex gap-2.5 justify-center items-center h-10 rounded-xl"},[_c('div',{staticClass:"font-medium leading-tight text-info text-fs-1_5"},[_c('NuxtLink',{attrs:{"to":_vm.searchResultsByQueryURL(_vm.query)},nativeOn:{"mousedown":function($event){return _vm.$emit('navigate-mousedown')},"mouseup":function($event){return _vm.$emit('navigate-mouseup')},"click":function($event){_vm.$emit('navigate', _vm.searchResultsByQueryURL(_vm.query))}}},[_vm._v(_vm._s(_vm.$t('see_all_results')))])],1)])])],2):_vm._e()],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }