import { showToast, showErrorToast } from '~/utils/toast-notifications';
export default {
  getFavoritePosts(context) {
    context.commit('GET_FAVORITES_START');
    return this.dispatch('api/getFavoritesPost')
      .then((res) => {
        context.commit('SET_FEED', res.data);
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_FEED_LOADING', false);
      });
  },
  incrementReplyCount(context, payload) {
    if (payload && payload.postId)
      context.commit('INCREMENT_REPLYCOUNT', payload.postId);
  },
  incrementRepostCount(context, payload) {
    if (payload && payload.postId)
      context.commit('INCREMENT_REPOSTCOUNT', payload.postId);
  },
  likePost(context, payload) {
    this.dispatch('api/likePost', payload)
      .then((res) => {
        context.commit('UPDATE_POST', res.data);
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  favoritePost(context, payload) {
    this.dispatch('api/favoritePost', payload)
      .then((res) => {
        context.commit('REMOVE_POST_FROM_FEED', res.data);
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  getNextPosts(context, payload) {
    context.commit('SET_NEXT_POSTS_LOADING', true);
    return this.dispatch('api/getFavoritesPost', payload)
      .then((res) => {
        context.commit('APPEND_POSTS_TO_FEED', res.data);
        return res;
      })
      .catch((err) => {
        context.commit('GET_FAVORITES_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_NEXT_POSTS_LOADING', false);
      });
  },
  deleteUserPost(context, payload) {
    return this.dispatch('api/deleteUserPost', payload)
      .then((res) => {
        context.commit('DELETE_USER_POST_SUCCESS', res.data);
        showToast(window.$nuxt.$t('post_deletion_successful'));
      })
      .catch((err) => {
        context.commit('DELETE_USER_POST_ERROR', err);
        showErrorToast(window.$nuxt.$t('error_occured_message'));
      });
  },
};
