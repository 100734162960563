
import GlobeIcon from '~/components/ui/icons/GlobeIcon.vue';
import LockIcon from '~/components/ui/icons/LockIcon.vue';

/**
 * A small tag indicating a monetary change.
 *
 * The color is green if the gain was positive, and red otherwise.
 */
export default {
  name: 'PortfolioPrivacyTag',
  components: { GlobeIcon, LockIcon },
  props: {
    /**
     * The portfolio's privacy.
     */
    isPublic: {
      type: Boolean,
      required: true,
    },
    showText: {
      type: Boolean,
      default: false,
    },
    containerClasses: {
      type: String,
      required: false,
      default: 'py-1.5 rounded-lg text-fs-13-px text-fw-2',
    },
    iconSize: {
      type: String,
      required: false,
      default: '20',
    },
    widthOverride: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
