
import HelpIcon from '../../icons/HelpIcon.vue';
export default {
  name: 'PortfolioInfoButton',
  components: { HelpIcon },
  props: {
    showCircles: {
      type: Boolean,
      default: false,
    },
  },
};
