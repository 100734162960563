// import { NumberColorFormat } from '@faker-js/faker';
import { deltaPrefix } from './util';

function numberIsStrange(x: number): boolean {
  return [Infinity, -Infinity, NaN].includes(x);
}

/**
 * Displays the given number as a human-readable string.
 *
 * @param x - The number to display.
 * @param minDecimals - The minimum number of decimals to display. Avoid using
 *   so as to have a uniform experience across the app. You are only guaranteed
 *   to have a **minimum number of digits** as specified.
 * @param maxDecimals - The maximum number of decimals to display. Avoid using.
 * @returns The human readable string.
 */
export function number(
  x: number,
  minDecimals: number | undefined = 2,
  maxDecimals: number | undefined = 2
): string {
  const locale = window.$nuxt.$i18n.locale;
  if (window.innerWidth <= 1080 && x >= 1000) {
    minDecimals = 0;
    maxDecimals = 0;
  }

  // TODO: Internationalize the infinity symbol.
  return x === Infinity
    ? '∞'
    : x === -Infinity
    ? '-∞'
    : isNaN(x)
    ? 'NaN'
    : x.toLocaleString(locale, {
        minimumFractionDigits: minDecimals,
        maximumFractionDigits: maxDecimals,
      });
}

/**
 * Displays the given number as a delta-string, ie. a string that showcases
 * the difference in value.
 *
 * @param x - The number to display.
 * @param minDecimals - The number of decimals to display. Avoid using so as
 *   to have a uniform experience across the app. You are only guaranteed to
 *   have a **minimum number of digits** as specified.
 * @param omitSign - Allows you to omit the sign. **Avoid using in user-facing
 *   code for fear of breaking UX**.
 * @param ignoreWidthRestriction - Allows us to ignore width restriction in delta function
 *   in cases where we want to decide how many decimals we want to display on mobile.
 * @returns The human readable string.
 */
export function delta(
  x: number,
  minDecimals: number | undefined = 2,
  maxDecimals: number | undefined = 2,
  omitSign = false,
  ignoreWidthRestriction = false
): string {
  return (
    (!omitSign ? deltaPrefix(x) : '') +
    (window.innerWidth <= 1080
      ? ignoreWidthRestriction
        ? number(x, minDecimals, maxDecimals)
        : readableNumber(x)
      : number(x, minDecimals, maxDecimals))
  );
}

/**
 * Displays the given number as a relative delta-string, ie. a string that
 * showcases the difference in value.
 *
 * PERFORMS NO VALIDATION ON THE NUMBER VALUE.
 *
 * @param x - The number to display.
 * @param minDecimals - The number of decimals to display. Avoid using so as
 *   to have a uniform experience across the app. You are only guaranteed to
 *   have a **minimum number of digits** as specified.
 * @param omitSign - Allows you to omit the sign. **Avoid using in user-facing
 *   code for fear of breaking UX**.
 * @param ignoreWidthRestriction - Allows us to ignore width restriction in delta function
 *   in cases where we want to decide how many decimals we want to display on mobile.
 * @returns The human readable string.
 */
export function relativeDelta(
  x: number,
  minDecimals: number | undefined = 2,
  maxDecimals: number | undefined = 2,
  omitSign = false,
  ignoreWidthRestriction = false
): string {
  return (
    delta(x, minDecimals, maxDecimals, omitSign, ignoreWidthRestriction) +
    (numberIsStrange(x) ? '' : '%')
  );
}

/**
 * Used to parse the whole part of a number for shortened display on mobile
 * To use in pair with readableDecimals() which handles the decimal part
 *
 */
export function readableNumber(
  num: number,
  millionThreshold: number | undefined = 1000000
): string {
  let letter = '';
  const numAbs = Math.abs(num);
  if (numAbs >= 1000000000) {
    // Billion
    letter = 'B';
    num /= 1000000000;
  } else if (numAbs >= millionThreshold) {
    // Million
    letter = 'M';
    num /= 1000000;
  }
  // 2024-11-21 : Removing the K, new standard is to display about 6 full numbers
  // else if (numAbs >= 1000) {
  //   //Thousand
  //   letter = 'K';
  //   num /= 1000;
  // }
  return readableDecimals(num) + letter;
}

/**
 * Used to parse the decimal part of a number for shortened display on mobile
 * To use in pair with readableNumber() which handles the whole part
 *
 */
export function readableDecimals(num: number): string {
  const locale = window.$nuxt.$i18n.locale;
  // 2024-11-21 : Removing decimals for numbers greater than 1000
  if (window.innerWidth <= 1080 && num >= 1000) {
    return num.toLocaleString(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  let toReturn = num.toLocaleString(locale, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });
  if (toReturn.slice(-2) === '00') {
    toReturn = num.toLocaleString(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }
  return toReturn;
}
