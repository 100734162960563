import { render, staticRenderFns } from "./index.vue?vue&type=template&id=44519322"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=44519322&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProfileImg: require('/skade-pwa/components/ui/atom/ProfileImg.vue').default,DateFromNow: require('/skade-pwa/components/ui/atom/DateFromNow.vue').default})
