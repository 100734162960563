export default {
  INIT: (state) => {
    (state.trendingTickers = []),
      (state.trendingLosersTickers = null),
      (state.trendingGainersTickers = null),
      (state.trendingTickersError = null);
    state.trendingTickersLoading = false;
  },

  SET_TRENDING_TICKERS: (state, payload) => {
    state.trendingTickers = payload;
  },

  SET_TRENDING_GAINERS_TICKERS: (state, payload) => {
    state.trendingGainersTickers = payload;
  },

  SET_TRENDING_LOSERS_TICKERS: (state, payload) => {
    state.trendingLosersTickers = payload;
  },

  SET_TRENDING_TICKERS_ERROR: (state, er) => {
    state.trendingTickersError = er;
  },

  SET_TRENDING_TICKERS_LOADING: (state, isLoading) => {
    state.trendingTickersLoading = isLoading;
  },
};
