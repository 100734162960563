import { inject } from 'vue';

export function isScreenSize(minWidth: number, maxWidth: number): boolean {
  const screenWidth = window.screen.width;
  return screenWidth >= minWidth && screenWidth <= maxWidth;
}

export function isMobile(): boolean {
  return window.screen.width <= 768;
}

/**
 * Not corresponding to any Tailwind breakpoint
 * But this is the biggest size I found for tablet devices on landscape mode
 * So rather than using it for styling, this should be used to enable/disable features on any mobile device vs desktop
 */
export function isDesktop(): boolean {
  return window.screen.width > 1366;
}

export function isTablet() {
  return window.innerWidth > 768 && window.innerWidth <= 1024;
}
// This function is created to be injected in Toast notification component
export default function () {
  inject('isMobileResponsive', () => {
    return isMobile();
  });
}
