import { render, staticRenderFns } from "./CompanyTickersListModal.vue?vue&type=template&id=6233a028"
import script from "./CompanyTickersListModal.vue?vue&type=script&lang=js"
export * from "./CompanyTickersListModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Img: require('/skade-pwa/components/ui/atom/Img.vue').default,ChangePercent: require('/skade-pwa/components/ui/atom/ChangePercent.vue').default,Spinner: require('/skade-pwa/components/ui/atom/Spinner.vue').default})
