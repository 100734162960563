export default function ({ $toast, $isMobileResponsive }, inject) {
  // Create a custom axios instance
  const notify = {
    success(
      msg = 'Success !',
      options = {
        className: ['toasted-success'],
        duration: 2500,
        closeOnSwipe: false,
        showCloseButton: true,
        position: $isMobileResponsive ? 'bottom-center' : 'top-right',
      }
    ) {
      const successMsg = `<div style="display: flex; justify-content: space-between; width: -webkit-fill-available; align-items: center;">${msg} <span style="font-size: 18px;" onclick="this.closest('.toasted').remove()">&#10005;</span></div>`;
      $toast.success(successMsg, {
        ...options,
        icon: 'done',
      });
    },

    error(
      msg = 'Error !',
      options = {
        className: ['toasted-error'],
        duration: 2500,
        closeOnSwipe: false,
        showCloseButton: true,
        position: $isMobileResponsive ? 'bottom-center' : 'top-right',
      }
    ) {
      const errorMsg = `<div style="display: flex; justify-content: space-between; width: -webkit-fill-available; align-items: center;">${msg} <span style="font-size: 18px;" onclick="this.closest('.toasted').remove()">&#10005;</span></div>`;

      $toast.error(errorMsg, { ...options, icon: 'info_outlined' });
    },

    info(
      msg = 'Info !',
      options = {
        className: ['toasted-info'],
        duration: 2500,
        closeOnSwipe: false,
        showCloseButton: true,
      }
    ) {
      const closeButton = options.showCloseButton
        ? '<span class="toast-close" onclick="this.closest(\'.toasted\').remove()">&#10005;</span>'
        : '';
      $toast.info(msg + closeButton, { ...options, icon: 'info_outlined' });
    },
    warning(
      msg = 'Warning !',
      options = {
        className: ['toasted-warning'],
        duration: 2500,
        closeOnSwipe: false,
        showCloseButton: true,
      }
    ) {
      const closeButton = options.showCloseButton
        ? '<span class="toast-close" onclick="this.closest(\'.toasted\').remove()">&#10005;</span>'
        : '';
      $toast.warning(msg + closeButton, { ...options, icon: 'info_outlined' });
    },
  };

  // Inject to context as $api
  inject('notify', notify);
}
