
export default {
  name: 'CustomDrawer',
  props: {
    // Used to trigger the animation
    show: {
      type: Boolean,
      default: false,
    },
    // Determines if we want to use animation in this component or not
    useAnimation: {
      type: Boolean,
      default: false,
    },
    // Animation type
    animationType: {
      type: String,
      default: '',
    },
    // How long animation lasts, currently used for removing overflow to re-enable scroll
    animationLength: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    show(newValue) {
      if (newValue) {
        document.body.style.overflow = 'hidden'; // Prevent background scroll
      } else {
        setTimeout(() => {
          document.body.style.overflow = '';
        }, this.animationLength); // Re-enable scroll when closed
      }
    },
  },
  beforeDestroy() {
    document.body.style.overflow = ''; // Ensure scroll is restored if component is destroyed
  },
};
