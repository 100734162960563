// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (min-width:1024px){.index__container{max-width:1249px}}@media (min-width:768px){.index__container .sidebar-container{flex-basis:48px}}@media (min-width:1024px){.index__container .sidebar-container{flex-basis:160px}}@media (min-width:1280px){.index__container .sidebar-container{flex-basis:195px}}@media (min-width:768px) and (min-width:1024px){.index__container .main{width:calc(100% - 70px)}}@media (min-width:1024px){.index__container .main{width:510px}}.index__container .complementary{flex-basis:300px}@media (min-width:1280px){.index__container .complementary{flex-basis:366px}}.index__container .complementary .widget_container{-ms-overflow-style:none;max-width:290px;scrollbar-width:none}@media (min-width:1280px){.index__container .complementary .widget_container{max-width:366px}}.index__container .complementary .widget_container::-webkit-scrollbar{display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"a": "#5a80fb",
	"b": "#6b88fc",
	"c": "#7b91fc",
	"d": "#8899fd",
	"e": "#95a2fd",
	"f": "#a1abfe",
	"g": "#adb4fe",
	"h": "#b8bdfe",
	"i": "#c3c6ff",
	"j": "#cdcfff",
	"k": "#d8d9ff",
	"l": "#e2e2ff",
	"m": "#ececff",
	"n": "#f5f5ff",
	"o": "#fff"
};
module.exports = ___CSS_LOADER_EXPORT___;
