
export default {
  name: 'ChangePercent',
  props: {
    change: {
      type: Number,
      required: true,
      default: 0,
    },
    precision: {
      type: Number,
      default: 2,
    },
    paddingX: {
      type: Number,
      default: 1,
    },
    // Allows us to ignore mobile decimal restriction when displaying change percent
    ignoreMobileDecimalRestriction: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: [String, null],
      required: false,
      default: null,
    },
    fontWeight: {
      type: [String, null],
      required: false,
      default: null,
    },
  },
  computed: {
    changeClass() {
      // TODO add fw and fs controls
      const newClass = `change-precent ${
        this.change < 0 ? 'change-negative' : 'change-positive'
      } ${this.fontSize ?? 'text-fs-1_5'} ${
        this.fontWeight ?? 'text-fw-3'
      } leading-4 h-4`;

      return newClass;
    },
  },
};
