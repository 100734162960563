
import { buildAssetUri } from '@/utils/mixins';
import { ceil } from 'lodash';
import StarIcon from '~/components/ui/icons/StarIcon.vue';
import PortfolioPrivacyTag from '../../atom/PortfolioPrivacyTag.vue';

export default {
  name: 'UIPortfolioCard',
  components: {
    StarIcon,
    PortfolioPrivacyTag,
  },
  mixins: [buildAssetUri],
  props: {
    /**
     * Rank information
     * If 0, no medals displayed
     */
    rank: {
      type: Number,
      default: 0,
    },
    /**
     * Overall return of the portfolio (%)
     */
    overallReturn: {
      type: Number,
      required: true,
    },
    /**
     * Today's return (%)
     */
    todayReturn: {
      type: Number,
      required: true,
    },
    /**
     * Name of the portfolio
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * Url of the profile picture
     * of the portfolio's owner
     */
    profileImg: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * Display name of the portfolio owner
     * used for default profile picture
     */
    ownerDisplayName: {
      type: String,
      required: true,
    },
    /**
     * ID of the profile owning the portfolio
     */
    ownerId: {
      type: String,
      required: true,
    },
    /**
     * Wether the user likes the portfolio or not
     */
    isLiked: {
      type: Boolean,
      default: false,
    },
    /**
     * Wether the user likes the portfolio or not
     */
    isFavorited: {
      type: Boolean,
      default: false,
    },
    /**
     * The like count of the portfolio
     */
    likeCount: {
      type: Number,
      required: true,
    },
    /**
     * The favorite count of the portfolio
     */
    favoriteCount: {
      type: Number,
      required: true,
    },
    /**
     * Wether we should display Today's performance
     */
    showToday: {
      type: Boolean,
      required: false,
      default: true,
    },
    /**
     * Wether we should display Today's performance
     */
    commentCount: {
      type: Number,
      required: true,
    },
    /**
     * Wether we should display Overall performance
     */
    showOverall: {
      type: Boolean,
      required: false,
      default: true,
    },
    /**
     * Wether we should display social interactions
     */
    showSocialInteractions: {
      type: Boolean,
      required: false,
      default: true,
    },
    /**
     * Current user ID
     */
    profileId: {
      type: String,
      required: false,
      default: '',
    },
    showRankingBorders: {
      type: Boolean,
      required: false,
      default: true,
    },
    /**
     * Wether we should display the compare button
     */
    showCompare: {
      type: Boolean,
      required: false,
      default: false,
    },
    isOnboarding: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPublic: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Allows us to ignore mobile decimal restriction when displaying change percent
     */
    ignoreMobileDecimalRestriction: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRepost: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
    'onPortfolioClick',
    'onProfileClick',
    'onLike',
    'onFavoritePortfolio',
    'onCard',
  ],
  data() {
    return {
      defaultUri: require('@/assets/img/unknow-user.jpg'),
    };
  },
  computed: {
    favoriteIconFill() {
      return this.isFavorited ? 'neutral-10' : '';
    },
    favoriteIconStroke() {
      return this.isFavorited ? 'neutral-10' : '';
    },
    favoriteTooltip() {
      return this.isFavorited
        ? this.$t('portfolio_remove_favorite')
        : this.$t('portfolio_add_favorite');
    },
    isMyPortfolio() {
      return this.ownerId === this.profileId;
    },
    displayTodayReturn() {
      return this.todayReturn ? ceil(this.todayReturn, 2) : 0;
    },
    todayReturnTooltip() {
      return (
        (this.todayReturn
          ? parseFloat(ceil(this.todayReturn, 4)).toFixed(4)
          : '0') + '%'
      );
    },
    showBorder() {
      return this.isRepost;
    },
  },
  methods: {
    getBorderColor() {
      if (!this.showRankingBorders) {
        return '';
      }
      if (this.rank < 0 || this.rank > 3) {
        return '';
      }
      const res = 'border-t-4.5 border-b-2 border-l-2 border-r-2';
      switch (this.rank) {
        case 1:
          return res + ' border-yellow-500';
        case 2:
          return res + ' border-gray-400';
        case 3:
          return res + ' border-yellow-700';
        default:
          return '';
      }
    },
    getBackgroundColor() {
      const background =
        this.isFavorited && this.isOnboarding
          ? ' favorited-card-background'
          : '';
      return background;
    },
    getFirstParentClasses() {
      const classes = `${this.isRepost ? 'w-min' : ''}`;

      return classes;
    },
  },
};
