export default {
  getNotifications(context, payload) {
    const noSkeletons = payload.noSkeletons ?? false;

    if (!noSkeletons) context.commit('INIT');

    context.commit('SET_LOADING', true);
    this.dispatch('api/getNotifications', payload)
      .then((res) => {
        context.commit('SET_NOTIFICATIONS', res.data.notificationsList);
        context.commit(
          'SET_HAS_MORE_NOTIFICATIONS',
          res.data.hasMoreNotifications
        );
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_LOADING', false);
      });
  },
  getNotificationCount(context, payload) {
    this.dispatch('api/notificationsCount', payload)
      .then((res) => {
        context.commit('SET_NOTIFICATION_COUNT', res.data);
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  getNextNotifications(context, payload) {
    context.commit('SET_NEXT_NOTIFICATIONS_LOADING', true);
    return this.dispatch('api/getNotifications', payload)
      .then((res) => {
        context.commit('APPEND_NEXT_NOTIFICATIONS', res.data.notificationsList);
        context.commit('INCREASE_OFFSET');
        context.commit(
          'SET_HAS_MORE_NOTIFICATIONS',
          res.data.hasMoreNotifications
        );
        return res;
      })
      .catch((err) => {
        context.commit('SET_NEXT_NOTIFICATIONS_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_NEXT_NOTIFICATIONS_LOADING', false);
      });
  },
  toggleNotificationRead(context, id) {
    this.dispatch('api/handleReadNotification', id)
      .then((res) => {
        const mutation = res.data.read ? 'MARK_AS_READ' : 'MARK_AS_UNREAD';
        context.commit(mutation, res.data.notificationId);
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  markAllNotificationsAsRead(context) {
    this.dispatch('api/handleReadAllNotification')
      .then(() => {
        context.commit('MARK_ALL_AS_READ');
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  markMultipleNotificationsAsRead(context, payload) {
    const { notificationIds } = payload;

    this.dispatch(`api/handleReadMultipleNotifications`, payload)
      .then(() => {
        context.commit('MARK_MULTIPLE_AS_READ', notificationIds);
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  removeNotification(context, id) {
    this.dispatch('api/removeNotification', id)
      .then(() => {
        context.commit('REMOVE_NOTIFICATION', id);
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  addNewNotification(context, notification) {
    context.commit('APPEND_NEW_NOTIFICATION', notification);
  },
  resetNotificationsOffset(context) {
    context.commit('RESET_NOTIFICATIONS_OFFSET');
  },
  getNotificationTypes(context) {
    this.dispatch('api/getNotificationTypes')
      .then((res) => {
        if (res.data) context.commit('SET_NOTIFICATION_TYPES', res.data);
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  getPushNotificationSettings(context) {
    context.commit('SET_LOADING', true);
    this.dispatch('api/getPushNotificationSettings')
      .then((res) => {
        context.commit('SET_LOADING', false);
        if (res) context.commit('SET_PUSH_NOTIFICATIONS_SETTINGS', res);
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  togglePushNotification(context, payload) {
    context.commit('SET_LOADING', true);
    this.dispatch('api/togglePushNotification', payload)
      .then((res) => {
        context.commit('SET_LOADING', false);
        if (res) context.commit('TOGGLE_PUSH_NOTIFICATION_SETTING', res);
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  resetPushNotifications(context, payload) {
    context.commit('SET_LOADING', true);
    this.dispatch('api/resetPushNotifications', payload)
      .then((res) => {
        context.commit('SET_LOADING', false);
        if (res) context.commit('SET_PUSH_NOTIFICATIONS_SETTINGS', res);
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
};
